/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState, useEffect } from 'react';
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import {
  getRoutes,
  addRoute,
  editRoute,
  deleteRoute,
} from '../../store/actions/routeInfo';
import { getCustomers } from '../../store/actions/customers';
import { getEmployees } from '../../store/actions/employees';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  Progress,
  DropdownItem,
  DropdownMenu,
  Media,
  Badge,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  ModalFooter,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import DefaultHeader from 'components/Headers/DefaultHeader';

const RouteInfo = (props) => {
  const [routeName, setRouteName] = useState('');
  const [day, setDay] = useState('sunday');
  const [manager, setManager] = useState('');
  const [copiedText, setCopiedText] = useState();
  const [modal, setModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [ids, setIds] = useState('');

  const toggle = () => {
    setModal(!modal);
  };
  const createToggle = () => {
    setCreateModal(!createModal);
  };
  const editToggle = () => {
    setEditModal(!editModal);
  };

  const disabledCheck = (id) => {
    let flag = false;

    let arr = [];
    arr = props.customers && props.customers.find((item) => item.route == id);
    // console.log('as', arr);

    if (arr) {
      flag = true;
    }

    return flag;
  };

  useEffect(() => {
    props.getCustomers();
    props.getRoutes();
    props.getEmployees(props.auth.uid);

    const user = props.routes;
    const customer = props.customers;
    console.log('customers', customer);
  }, []);
  return (
    <>
      <DefaultHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent d-flex">
                <h3 className="mb-0">Route Management</h3>
                <Button
                  onClick={createToggle}
                  color="primary"
                  className="ml-auto"
                >
                  Create Route
                </Button>
              </CardHeader>

              {/* Create Route Model */}
              <Modal isOpen={createModal} toggle={createToggle}>
                <ModalHeader toggle={createToggle}>Create Route</ModalHeader>
                <ModalBody>
                  <div>
                    <Label>Route Name</Label>
                    <Input
                      type="text"
                      name="firstname"
                      required
                      onChange={(e) => setRouteName(e.target.value)}
                      placeholder="Enter Route Name"
                    />
                    <Label className="mt-2">Route Day</Label>
                    <Input
                      type="select"
                      id="days"
                      defaultValue={day}
                      required
                      onChange={(e) => setDay(e.target.value)}
                      name="days"
                    >
                      <option value="sunday">Sunday</option>
                      <option value="monday">Monday</option>
                      <option value="tuesday">Tuesday</option>
                      <option value="wednesday">Wednesday</option>
                      <option value="thursday">Thursday</option>
                      <option value="friday">Friday</option>
                      <option value="saturday">Saturday</option>
                    </Input>

                    <Label className="mt-2">Route Manager</Label>
                    <Input
                      type="select"
                      id="routemanager"
                      onChange={(e) => setManager(e.target.value)}
                      name="routemanager"
                    >
                      <option value="default">Select Route Manager</option>
                      {props.employees &&
                        props.employees.map((emp) => (
                          <option key={emp.id}>
                            {emp.firstName} {emp.lastName}
                          </option>
                        ))}
                    </Input>
                    {/* <Label className='mt-2'>Route Manger</Label>
                    <Input type='select' id='manger'     onChange={(e)=>setManager(e.target.value)} name='manger'>
                      <option value='John Smith'>John Smith</option>
                      <option value='Andrew Schulkz'>Andrew Schulkz</option>
                      <option value='Joe Denly'>Joe Denly</option>
                    </Input> */}
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      let obj = { routeName, day, manager };
                      if (routeName == '') {
                        alert('route Name is empty');
                      } else {
                        props.addRoute(obj);
                      }
                      createToggle();
                    }}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </Modal>
              {/* Ended */}
              {/* Create Route Model */}
              <Modal isOpen={editModal} toggle={editToggle}>
                <ModalHeader toggle={editToggle}>Edit Route</ModalHeader>
                <ModalBody>
                  <div>
                    <Label>Route Name</Label>
                    <Input
                      type="text"
                      name="firstname"
                      onChange={(e) => setRouteName(e.target.value)}
                      defaultValue={routeName}
                    />
                    <Label className="mt-2">Route Day</Label>
                    <Input
                      type="select"
                      id="days"
                      onChange={(e) => setDay(e.target.value)}
                      defaultValue={day}
                      name="days"
                    >
                      <option value="Sunday">Sunday</option>
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Saturday">Saturday</option>
                    </Input>
                    <Label className="mt-2">Route Manger</Label>
                    <Input
                      type="select"
                      id="manger"
                      onChange={(e) => setManager(e.target.value)}
                      defaultValue={manager}
                      name="days"
                    >
                      <option value="John Smith">John Smith</option>
                      <option value="Andrew Schulkz">Andrew Schulkz</option>
                      <option value="Joe Denly">Joe Denly</option>
                    </Input>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      let obj = { routeName, day, manager };
                      props.editRoute(ids, obj);
                      setRouteName('');
                      setDay('sunday');
                      setManager('John Smith');

                      editToggle();
                    }}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </Modal>
              {/* Ended */}
              {/* Delete Modal */}
              <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Delete Route</ModalHeader>
                <ModalBody>Are you sure you want to delete ? </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      props.deleteRoute(ids);
                      toggle();
                    }}
                  >
                    Confirm
                  </Button>
                  <Button color="danger" onClick={toggle}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
              {/* Ended */}
              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Route Name</th>
                      <th scope="col">Route Day</th>
                      <th scope="col">Route Manger</th>

                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.routes &&
                      props.routes.map((route) => (
                        <tr key={route.id}>
                          <th scope="row">
                            <span className="mb-0 text-sm">
                              {route.routeName}
                            </span>
                          </th>
                          <td>{route.day}</td>
                          <td>
                            <span className="mb-0 text-sm">
                              {route.manager}
                            </span>
                          </td>

                          <td>
                            <div className="d-flex align-items-center">
                              <Button
                                onClick={() => {
                                  let obj = route;
                                  setRouteName(obj.routeName);
                                  setDay(obj.day);
                                  setManager(obj.manager);
                                  setIds(obj.id);
                                  editToggle();
                                }}
                                color="secondary"
                                size="sm"
                              >
                                <i className="fas fa-edit" />
                              </Button>
                              <Button
                                disabled={disabledCheck(route.id)}
                                onClick={() => {
                                  let obj = route.id;
                                  setIds(obj);
                                  toggle();
                                }}
                                color="danger"
                                size="sm"
                              >
                                <i className="fas fa-trash" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    routes: state.mdp.routes,
    customers: state.mdp.customers,
    employees: state.mdp.employees,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    getCustomers: () => dispatch(getCustomers()),
    getRoutes: () => dispatch(getRoutes()),
    addRoute: (object) => dispatch(addRoute(object)),
    editRoute: (id, object) => dispatch(editRoute(id, object)),
    deleteRoute: (id) => dispatch(deleteRoute(id)),
    getEmployees: (id) => dispatch(getEmployees(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteInfo);
