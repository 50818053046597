import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
import axios from "axios";
import { useHistory } from "react-router";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Form,
  Spinner,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
import { firstPasswordUpdated } from "../store/actions/authActions";
import { getToken } from "store/actions/generalActions";
// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";
import { getAdmin } from "store/actions/generalActions";
const Index = (props) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  let auth = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  let dispatch = useDispatch();
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };
  useEffect(() => {
    if (auth.user.account == "not_verified" && !auth.asAdmin) {
      setOpen(true);
    }
    dispatch(getAdmin());
  }, []);
  useEffect(() => {
    if (auth.user.account == "verified") {
      setOpen(false);
    }
  }, [auth]);
  if (!auth.uid) {
    return <Redirect to="/auth/login" />;
  }
  return (
    <>
      <Header />
      <Container className="" fluid>
        <Modal centered isOpen={open}>
          <ModalHeader>Update Password</ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                if (newPassword !== confirmPassword) {
                  toast.warning("New Password didn't match");
                } else {
                  let obj = {
                    userPassword: currentPassword,
                    newPassword: newPassword,
                    uid: auth.uid,
                  };
                  dispatch(firstPasswordUpdated(obj));
                  // if (auth.isToggle) {
                  //   console.log('wes asd')
                  //   toggle()
                  // }
                }
              }}
            >
              <Label>Current Password</Label>
              <Input
                required
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <Label className="mt-2">New Password</Label>
              <Input
                required
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <Label className="mt-2">Confirm New Password</Label>
              <Input
                required
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div className="mt-2 text-right" disabled={auth.passwordLoading}>
                <Button color="primary" type="submit">
                  {auth.passwordLoading ? <Spinner size="sm" /> : "Update"}
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </Container>
    </>
  );
};

export default Index;
