import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { useSelector } from 'react-redux'
import SuperAdminLogin from 'views/examples/SuperAdminLogin';

function App() {
  let auth = useSelector(state => state.auth);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth/login/:id" render={(props) => <SuperAdminLogin {...props} />} />
        {auth.uid ? (
          <>
            <Route path='/admin' render={(props) => <AdminLayout {...props} />} />
            <Redirect from='/' to='/admin' />
          </>
        ) : (
          <>
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Redirect from='/' to='/auth/login' />
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
}

export default App
