import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
} from "reactstrap";
import { updateBusiness } from "store/actions/businessActions";
import { addBusiness } from "store/actions/businessActions";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";

const libraries = ["places"];

const WarehouseLocation = ({
  modal,
  modalToggler,
  editMode,
  activeBusiness,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyD3Qm4SGSVkO1sQK6zQYoUg16xpUqJunkc",
    libraries,
  });
  const { isAdding, isUpdating } = useSelector((state) => state.business);
  const [activeStep, setActiveStep] = useState(1);
  const stepsConfig = [
    {
      step: 1,
      modalSize: "md",
      title: "Add Location",
    },
    {
      step: 2,
      modalSize: "sm",
      title: "What Services Do You Offer?",
    },
    {
      step: 3,
      modalSize: "lg",
      title: "Buisness Hours",
    },
    {
      step: 4,
      modalSize: "md",
      title: "What Brands Do You Sell?",
    },
  ];
  const [locationData, setLocationData] = useState({
    locationName: "",
    address: "",
    phone: "",
    website: "",
    facebook: "",
    instagram: "",
    youtube: "",
  });
  const [servicesData, setServicesData] = useState({
    shoppingStore: false,
    spotFreeWater: false,
    truckSales: false,
    onlineStore: false,
    supplierMember: false,
  });
  const [brandText, setBrandText] = useState("");

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [businessHours, setBusinessHours] = useState(
    weekdays.map((day) => ({
      day,
      open: "",
      close: "",
      closed: false,
    }))
  );
  const [error, setError] = useState("");

  const validatePhoneNumber = (phone) => {
    const phoneDigits = phone.replace(/\D/g, "");
    if (phoneDigits.length !== 10) {
      setError("Please enter a valid 10-digit phone number.");
    } else {
      setError("");
    }
  };

  const handlePlaceChanged = (autocomplete) => {
    const place = autocomplete.getPlace();
    setLocationData((prevLocationData) => ({
      ...prevLocationData,
      address: place.formatted_address,
    }));
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setLocationData({
      ...locationData,
      [name]: value,
    });
  };

  const handleServiceChange = (e) => {
    const { name, checked } = e.target;
    setServicesData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleBack = () => {
    if (activeStep === 4 && !servicesData?.shoppingStore) {
      setActiveStep(activeStep - 2);
      return;
    }
    setActiveStep(activeStep - 1);
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (error) {
      return;
    }
    if (activeStep === 2 && !servicesData?.shoppingStore) {
      setActiveStep(activeStep + 2);
      return;
    }
    setActiveStep(activeStep + 1);
  };

  const handleTimeChange = (index, type, value) => {
    const newHours = [...businessHours];
    newHours[index][type] = value;
    setBusinessHours(newHours);
  };

  const handleCheckboxChange = (index) => {
    const newHours = [...businessHours];
    newHours[index].closed = !newHours[index].closed;
    if (newHours[index].closed) {
      newHours[index].open = "";
      newHours[index].close = "";
    }
    setBusinessHours(newHours);
  };

  const handleResetState = () => {
    setActiveStep(1);
    setLocationData({
      locationName: "",
      address: "",
      phone: "",
      website: "",
      facebook: "",
      instagram: "",
      youtube: "",
    });
    setServicesData({
      shoppingStore: false,
      truckSales: false,
      onlineStore: false,
      supplierMember: false,
    });
    setBrandText("");
    setBusinessHours(
      weekdays.map((day) => ({
        day,
        open: "",
        close: "",
        closed: false,
      }))
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      ...locationData,
      ...servicesData,
      businessHours: servicesData?.shoppingStore && businessHours,
      brandsYouSell: brandText,
    };

    if (!editMode) {
      dispatch(
        addBusiness({
          uid: user?.id,
          payload,
          onSuccess: () => {
            modalToggler();
            handleResetState();
          },
        })
      );
    } else {
      dispatch(
        updateBusiness({
          businessId: activeBusiness?.id,
          payload,
          onSuccess: () => {
            modalToggler();
            handleResetState();
          },
        })
      );
    }
  };

  useEffect(() => {
    if (!modal) {
      handleResetState();
    }
  }, [modal]);

  useEffect(() => {
    if (editMode && activeBusiness) {
      setLocationData({
        locationName: activeBusiness.locationName || "",
        address: activeBusiness.address || "",
        phone: activeBusiness.phone || "",
        website: activeBusiness.website || "",
        facebook: activeBusiness.facebook || "",
        instagram: activeBusiness.instagram || "",
        youtube: activeBusiness.youtube || "",
      });

      setServicesData({
        shoppingStore: activeBusiness?.shoppingStore,
        spotFreeWater: activeBusiness?.spotFreeWater,
        truckSales: activeBusiness?.truckSales,
        onlineStore: activeBusiness?.onlineStore,
        supplierMember: activeBusiness?.supplierMember,
      });

      setBrandText(activeBusiness.brandsYouSell || "");

      if (activeBusiness.businessHours) {
        setBusinessHours(
          weekdays.map((day) => {
            const dayHours = activeBusiness.businessHours.find(
              (hours) => hours.day === day
            );
            return {
              day,
              open: dayHours?.open || "",
              close: dayHours?.close || "",
              closed: dayHours?.closed || false,
            };
          })
        );
      }
    }
  }, [editMode]);

  return (
    <Modal
      isOpen={modal}
      toggle={modalToggler}
      size={stepsConfig[activeStep - 1]?.modalSize}
    >
      <ModalHeader toggle={modalToggler}>
        <h3>{stepsConfig[activeStep - 1]?.title}</h3>
      </ModalHeader>
      <ModalBody>
        {activeStep === 1 && (
          <Form type="form" id="addLocation" onSubmit={handleNext}>
            <Label>Warehouse Location</Label>
            <Input
              type="text"
              name="locationName"
              placeholder="Enter Location Name"
              required
              value={locationData?.locationName}
              onChange={handleLocationChange}
            />
            <Label className="mt-2">Address</Label>
            {isLoaded && (
              <Autocomplete
                onLoad={(autocomplete) => {
                  autocomplete.addListener("place_changed", () =>
                    handlePlaceChanged(autocomplete)
                  );
                }}
              >
                <Input
                  type="text"
                  name="address"
                  placeholder="Enter your address"
                  value={locationData?.address}
                  onChange={handleLocationChange}
                />
              </Autocomplete>
            )}
            <Label className="mt-2">Phone Number</Label>
            <Input
              type="text"
              name="phone"
              placeholder="Enter Your Phone Number"
              required
              value={locationData?.phone}
              onChange={(e) => {
                let value = e.target.value.replace(/\D/g, "");
                if (value.length > 0) {
                  value = `(${value.substring(0, 3)})${value.substring(3, 6)}${
                    value.length > 6 ? `-${value.substring(6, 10)}` : ""
                  }`;
                }
                handleLocationChange({
                  target: {
                    name: e.target.name,
                    value,
                  },
                });
              }}
              onBlur={(e) => validatePhoneNumber(e.target.value)}
            />
            {error && <div className="text-danger text-sm mt-1">{error}</div>}
            <Label className="mt-2 d-block">Website</Label>
            <Input
              type="url"
              name="website"
              placeholder="Enter Website Url"
              value={locationData?.website}
              onChange={handleLocationChange}
            />
            <Label className="mt-2">Facebook</Label>
            <Input
              type="url"
              name="facebook"
              placeholder="Enter Facebook Url"
              value={locationData?.facebook}
              onChange={handleLocationChange}
            />
            <Label className="mt-2">Instagram</Label>
            <Input
              type="url"
              name="instagram"
              placeholder="Enter Instagram Url"
              value={locationData?.instagram}
              onChange={handleLocationChange}
            />
            <Label className="mt-2">Youtube</Label>
            <Input
              type="url"
              name="youtube"
              placeholder="Enter Youtube Url"
              value={locationData?.youtube}
              onChange={handleLocationChange}
            />
          </Form>
        )}
        {activeStep === 2 && (
          <Form type="form" id="addLocation" onSubmit={handleNext}>
            <FormGroup check className="custom-checkbox">
              <Input
                type="checkbox"
                name="shoppingStore"
                className="custom-checkbox-input"
                checked={servicesData?.shoppingStore}
                onChange={handleServiceChange}
              />
              <Label check className="custom-checkbox-label">
                In Store Shopping
              </Label>
            </FormGroup>
            <FormGroup check className="custom-checkbox">
              <Input
                type="checkbox"
                name="spotFreeWater"
                className="custom-checkbox-input"
                checked={servicesData?.spotFreeWater}
                onChange={handleServiceChange}
              />
              <Label check className="custom-checkbox-label">
                Spot Free Water
              </Label>
            </FormGroup>
            <FormGroup check className="custom-checkbox">
              <Input
                type="checkbox"
                name="truckSales"
                className="custom-checkbox-input"
                checked={servicesData?.truckSales}
                onChange={handleServiceChange}
              />
              <Label check className="custom-checkbox-label">
                Routed Truck Sales
              </Label>
            </FormGroup>
            <FormGroup check className="custom-checkbox">
              <Input
                type="checkbox"
                name="onlineStore"
                className="custom-checkbox-input"
                checked={servicesData?.onlineStore}
                onChange={handleServiceChange}
              />
              <Label check className="custom-checkbox-label">
                Online Store
              </Label>
            </FormGroup>
            <FormGroup check className="custom-checkbox">
              <Input
                type="checkbox"
                name="supplierMember"
                className="custom-checkbox-input"
                checked={servicesData?.supplierMember}
                onChange={handleServiceChange}
              />
              <Label check className="custom-checkbox-label">
                IDA Supplier Member
              </Label>
            </FormGroup>
          </Form>
        )}
        {activeStep === 3 && (
          <Form type="form" id="addLocation" onSubmit={handleNext}>
            <Table className="align-items-center table-flush border" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Day</th>
                  <th scope="col">Open</th>
                  <th scope="col">Close</th>
                  <th scope="col">Closed</th>
                </tr>
              </thead>
              <tbody>
                {businessHours.map((hour, index) => (
                  <tr key={index}>
                    <th scope="row">{hour.day}</th>
                    <td scope="row">
                      <Input
                        type="time"
                        value={hour?.open}
                        required={!hour?.closed}
                        onChange={(e) =>
                          handleTimeChange(index, "open", e.target.value)
                        }
                        disabled={hour?.closed}
                      />
                    </td>
                    <td scope="row">
                      <Input
                        type="time"
                        value={hour?.close}
                        required={!hour?.closed}
                        onChange={(e) =>
                          handleTimeChange(index, "close", e.target.value)
                        }
                        disabled={hour?.closed}
                      />
                    </td>
                    <td scope="row">
                      <span className="custom-checkbox ml-4 mt-3 position-relative">
                        <Input
                          type="checkbox"
                          className="custom-checkbox-input"
                          required={!hour?.open && hour?.close}
                          checked={hour?.closed}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Form>
        )}
        {activeStep === 4 && (
          <Form type="form" id="addLocation" onSubmit={handleSubmit}>
            <Label className="mt-2">Description</Label>
            <Input
              type="textarea"
              name="brandText"
              rows={6}
              placeholder="List all the brands you offer"
              required
              value={brandText}
              onChange={(e) => setBrandText(e.target.value)}
            />
          </Form>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        {activeStep !== 1 && (
          <Button color="warning" onClick={handleBack}>
            Back
          </Button>
        )}
        <Button
          type="submit"
          color="primary"
          className="ml-auto"
          form="addLocation"
          disabled={editMode ? isUpdating : isAdding}
        >
          {activeStep === 4 ? "Save" : "Next"}
          {(editMode ? isUpdating : isAdding) && (
            <Spinner size="sm" className="ml-2" />
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WarehouseLocation;
