import DefaultHeader from 'components/Headers/DefaultHeader'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardBody, CardHeader, Container, Row, Table } from 'reactstrap'
import { useHistory } from 'react-router'
import { getTestCustomers, getToken } from 'store/actions/generalActions'
import { getAdmin } from 'store/actions/generalActions'

function Customers() {
    const admin_creds = JSON.parse(localStorage.getItem("admin_creds"))
    let general = useSelector(state => state.general)
    let auth = useSelector(state => state.auth)
    let dispatch = useDispatch()
    let history = useHistory()
    const authUriResponse = async () => {
        let obj = {
            customerId: admin_creds[0].client_key,
            customerSecret: admin_creds[0].secret_key
        }
        const response = await axios.post(
            'https://us-central1-mdp-platform.cloudfunctions.net/authUrl', obj
        )
        window.location.href = response.data
    }
    const getNewToken = async query => {
        let obj = {
            token: query,
            customerId: admin_creds[0].client_key,
            customerSecret: admin_creds[0].secret_key
        }
        dispatch(getToken(obj))
    }

    useEffect(() => {
        dispatch(getAdmin())
        const ls = JSON.parse(localStorage.getItem("token"))
        console.log("in genral if")
        if (ls == null) {
            console.log("in ls");
            if (!window.location.search) {
                authUriResponse()
            } else {
                getNewToken(window.location.href)
            }
        }
    }, [])
    let ls = JSON.parse(localStorage.getItem("token"))

    useEffect(() => {
        if (ls != null) {
            if (Object.keys(general.token).length != 0) {
                let obj = {
                    token: general.token.access_token,
                    refreshToken: general.token.refresh_token,
                    realmId: general.token.realmId,
                    customerId: admin_creds[0].client_key,
                    customerSecret: admin_creds[0].secret_key
                }
                // console.log('obj', obj)
                if (general.customerData.length == 0) {
                    dispatch(getTestCustomers(obj))
                }
            }
        }
    }, [ls])

    return (
        <>
            <DefaultHeader />
            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        {/* {
                            auth.user.client_key != undefined &&
                                auth.user.secret_key != undefined
                                ? */}
                        <Card className="shadow">
                            <CardHeader className="bg-transparent d-flex">
                                <h3 className="mb-0">Customers</h3>
                            </CardHeader>
                            <CardBody>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th >Phone Number</th>
                                            <th >Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            general.customerData.length != 0
                                            &&
                                            general.customerData.map((customer, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{customer.GivenName} {" "}{customer.FamilyName}</td>
                                                        <td>{customer.PrimaryPhone != undefined && customer.PrimaryPhone.FreeFormNumber}</td>
                                                        <td>{customer.Balance}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                        {/* :
                                <Card className="Shadow bg-danger mt-5">
                                    <CardBody className="text-center">
                                        <h3 className="text-white">
                                            <i className="fa fa-exclamation-triangle mr-3"></i> Kindly set your Client & Secret key's from Quickbook Dashboard
                                        </h3>
                                    </CardBody>
                                </Card>
                        } */}
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default Customers
