import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
} from "reactstrap";
import { updateBrand } from "store/actions/brandActions";
import { addBrand } from "store/actions/brandActions";
import { updateBusiness } from "store/actions/businessActions";

const Brand = ({ modal, modalToggler, editMode, activeBrand }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { actionLoading } = useSelector((state) => state.brand);
  const initialBrandData = {
    name: "",
    description: "",
    website: "",
    facebook: "",
    instagram: "",
    youtube: "",
  };
  const initialSalesData = {
    name: "",
    phone: "",
    email: "",
  };
  const [activeStep, setActiveStep] = useState(1);
  const [logo, setLogo] = useState(null);
  const [previousLogoUrl, setPreviousLogoUrl] = useState("");
  const [brandData, setBrandData] = useState(initialBrandData);
  const [salesContact, setSalesContact] = useState(initialSalesData);
  const [error, setError] = useState("");

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (!file.type.startsWith("image/")) {
      e.target.value = "";
      toast.error("Please select a valid image file.");
      return;
    }
    setLogo(file);
  };

  const handleBrandChange = (e) => {
    const { name, value } = e.target;
    setBrandData({
      ...brandData,
      [name]: value,
    });
  };

  const validatePhoneNumber = (phone) => {
    const phoneDigits = phone.replace(/\D/g, "");
    if (phoneDigits.length !== 10) {
      setError("Please enter a valid 10-digit phone number.");
    } else {
      setError("");
    }
  };

  const handleSalesContactChange = (e) => {
    const { name, value } = e.target;
    setSalesContact({
      ...salesContact,
      [name]: value,
    });
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = (e) => {
    e.preventDefault();
    setActiveStep(activeStep + 1);
  };

  const handleResetState = () => {
    setActiveStep(1);
    setBrandData(initialBrandData);
    setSalesContact(initialSalesData);
    setLogo(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      ...brandData,
      salesContact,
    };

    if (!editMode) {
      dispatch(
        addBrand({
          uid: user?.id,
          logo,
          payload,
        })
      ).then(() => {
        modalToggler();
        handleResetState();
      });
    } else {
      dispatch(
        updateBrand({
          brandId: activeBrand?.id,
          logo,
          previousLogoUrl,
          payload,
        })
      ).then(() => {
        modalToggler();
        handleResetState();
      });
    }
  };

  useEffect(() => {
    if (!modal) {
      handleResetState();
    }
  }, [modal]);

  useEffect(() => {
    if (editMode && activeBrand) {
      setPreviousLogoUrl(activeBrand?.logo?.url || "");
      setLogo({
        name: activeBrand?.logo?.name || "",
        url: activeBrand?.logo?.url || "",
      });
      setBrandData({
        name: activeBrand?.name || "",
        description: activeBrand?.description || "",
        website: activeBrand?.website || "",
        facebook: activeBrand?.facebook || "",
        instagram: activeBrand?.instagram || "",
        youtube: activeBrand?.youtube || "",
      });
      setSalesContact({
        name: activeBrand?.salesContact?.name || "",
        phone: activeBrand?.salesContact?.phone || "",
        email: activeBrand?.salesContact?.email || "",
      });
    }
  }, [editMode]);

  return (
    <Modal isOpen={modal} toggle={modalToggler}>
      <ModalHeader toggle={modalToggler}>
        <h3>{activeStep === 1 ? "Add Brand" : "Sales Contact"}</h3>
      </ModalHeader>
      <ModalBody>
        {activeStep === 1 && (
          <Form type="form" id="addBrand" onSubmit={handleNext}>
            <Label>Brand Logo</Label>
            <Input
              id="logo"
              type="file"
              name="logo"
              onChange={handleLogoChange}
              className="d-none"
            />
            <Label
              type="button"
              htmlFor="logo"
              className="d-block rounded p-2 text-truncate"
              style={{ border: "1px solid #CAD1D7" }}
            >
              {logo?.name || "No File Chosen"}
            </Label>
            <Label className="mt-2">Brand Name</Label>
            <Input
              type="text"
              name="name"
              placeholder="Enter Brand Name"
              required
              value={brandData?.name}
              onChange={handleBrandChange}
            />
            <Label className="mt-2">Brand Description</Label>
            <Input
              type="textarea"
              rows="4"
              name="description"
              placeholder="Enter Brand Description Here..."
              value={brandData?.description}
              onChange={handleBrandChange}
            />
            <Label className="mt-2 d-block">Website</Label>
            <Input
              type="url"
              name="website"
              placeholder="Enter Website Url"
              value={brandData?.website}
              onChange={handleBrandChange}
            />
            <Label className="mt-2">Facebook</Label>
            <Input
              type="url"
              name="facebook"
              placeholder="Enter Facebook Url"
              value={brandData?.facebook}
              onChange={handleBrandChange}
            />
            <Label className="mt-2">Instagram</Label>
            <Input
              type="url"
              name="instagram"
              placeholder="Enter Instagram Url"
              value={brandData?.instagram}
              onChange={handleBrandChange}
            />
            <Label className="mt-2">Youtube</Label>
            <Input
              type="url"
              name="youtube"
              placeholder="Enter Youtube Url"
              value={brandData?.youtube}
              onChange={handleBrandChange}
            />
          </Form>
        )}
        {activeStep === 2 && (
          <Form type="form" id="addBrand" onSubmit={handleSubmit}>
            <Label>Contact Name</Label>
            <Input
              type="text"
              name="name"
              placeholder="Enter Your Name"
              value={salesContact?.name}
              onChange={handleSalesContactChange}
            />
            <Label className="mt-2">Phone Number</Label>
            <Input
              type="text"
              name="phone"
              placeholder="Enter Your Phone Number"
              value={salesContact?.phone}
              onChange={(e) => {
                let value = e.target.value.replace(/\D/g, "");
                if (value.length > 0) {
                  value = `(${value.substring(0, 3)})${value.substring(3, 6)}${
                    value.length > 6 ? `-${value.substring(6, 10)}` : ""
                  }`;
                }
                handleSalesContactChange({
                  target: {
                    name: e.target.name,
                    value,
                  },
                });
              }}
              onBlur={(e) => validatePhoneNumber(e.target.value)}
            />
            {error && <div className="text-danger text-sm mt-1">{error}</div>}
            <Label className="mt-2">Email</Label>
            <Input
              type="email"
              name="email"
              placeholder="Enter Your Email"
              value={salesContact?.email}
              onChange={handleSalesContactChange}
            />
          </Form>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        {activeStep !== 1 && (
          <Button color="warning" onClick={handleBack}>
            Back
          </Button>
        )}
        <Button
          type="submit"
          color="primary"
          className="ml-auto"
          form="addBrand"
          disabled={actionLoading}
        >
          {activeStep === 1 ? "Next" : "Save"}
          {actionLoading && <Spinner size="sm" className="ml-2" />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Brand;
