
import firebase from '../../firebase';
export const getBusinesses = () => {
  return (dispatch) => {
    firebase.firestore().collection('businesses').onSnapshot(query => {
      var listData = [];
      query.forEach((item) => {

        listData.push({ id: item.id, ...item.data() });

      });
      dispatch({
        type: 'GET_BUSINESSES',
        payload: listData,
      });
    })
  };

}

export const addBusiness = (creds) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_BUSINESS'
    });
    firebase.firestore().collection('businesses').add(creds)
  };


}

export const editBusiness = (id, creds) => {
  return (dispatch) => {
    firebase.firestore().collection('businesses').doc(id).update({ ...creds });
    dispatch({
      type: 'EDIT_BUSINESS'
    });
  }
}
export const deleteBusiness = (id) => {
  return (dispatch) => {

    firebase.firestore().collection('businesses').doc(id).delete();
    dispatch({
      type: 'DELETE_BUSINESS'
    });
  }
}
export const clearData = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_DATA"
    })
  }
}