
import firebase from '../../firebase';
export const getCustomers = () =>
{
  return (dispatch) =>
  {
     firebase.firestore().collection('customers').onSnapshot(query =>
  {
    var listData = [];
    // var routeName='';
    query.forEach((item) => {
//         var routeId=item.data().route
//         console.log("id",item.data().route);
//      firebase.firestore().collection('routes').doc(routeId).get().then(querysnapshot=>{
//        console.log(querysnapshot.data());
//    })
   
      listData.push({ id: item.id, ...item.data() });
    });
    console.log(listData);
    dispatch({
      type: 'GET_CUSTOMERS',
      payload: listData,
    });
  })
  };
  
}

export const addCustomer = (creds) =>
{  
        console.log('creds', creds);
        return (dispatch)=>{
          dispatch({
            type:'ADD_CUSTOMER'
          });
          
          firebase.firestore().collection('customers').add(creds)
    };
  
 
}

export const editCustomer=(id,creds)=>{
  return(dispatch)=>{
   
    firebase.firestore().collection('customers').doc(id).update({...creds});
     dispatch({
      type:'EDIT_CUSTOMER'
    });
  }
}
export const deleteCustomer=(id)=>{
  return(dispatch)=>{
   
    firebase.firestore().collection('customers').doc(id).delete();
     dispatch({
      type:'DELETE_CUSTOMER'
    });
  }
}