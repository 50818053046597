const initState = {

  requested: false,
  businesses: [],
  employees: [],
  customers: [],
  routes: [],
  employeeLoader: false,
};

const allReducers = (state = initState, action) => {
  switch (action.type) {
    case 'GET_EMPLOYEES':
      return {
        ...state,
        employees: action.payload
      };
    case 'ADD_EMPLOYEE':
      return {
        ...state,
        employeeLoader: true,
      };
    case 'EDIT_EMPLOYEE':
      return {
        ...state,
      };
    case 'ADD_EMPLOYEE_END':
      return {
        ...state,
        employeeLoader: false,
      };
    case 'DELETE_EMPLOYEE':
      return {
        ...state
      };

    case 'GET_CUSTOMERS':
      return {
        ...state,
        customers: action.payload,
      };
    case 'ADD_CUSTOMER':
      return {
        ...state,
      };
    case 'EDIT_CUSTOMER':
      return {
        ...state,
      };
    case 'DELETE_CUSTOMER':
      return {
        ...state,
      };

    case 'ACTION_REQUEST':
      return {
        ...state,
        requested: true,
      };
    case 'ACTION_REQUEST_END':
      return {
        ...state,
        requested: false,
      };
    case 'GET_BUSINESSES':
      return {
        ...state,
        businesses: action.payload,
      };
    case 'ADD_BUSINESS':
      return {
        ...state,
      };
    case 'EDIT_BUSINESS':
      return {
        ...state,
      };
    case 'DELETE_BUSINESS':
      return {
        ...state,
      };
    case 'GET_ROUTES':
      return {
        ...state,
        routes: action.payload,
      };
    case 'ADD_ROUTE':
      return {
        ...state,
      };
    case 'EDIT_ROUTE':
      return {
        ...state,
      };
    case 'DELETE_ROUTE':
      return {
        ...state,
      };
    case 'CLEAR_DATA':
      return {
        requested: false,
        businesses: [],
        employees: [],
        customers: [],
        routes: [],
        employeeLoader: false,
      };
    default:
      return state;
  }
};

export default allReducers;
