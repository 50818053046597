import { useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { getCustomers } from "../../store/actions/customers";
import { getBusinesses } from "../../store/actions/businesses";
import { getEmployees } from "../../store/actions/employees";
import { getRoutes } from "../../store/actions/routeInfo";
import { Link } from "react-router-dom";
import { fetchBusiness } from "store/actions/businessActions";

const Header = (props) => {
  const dispatch = useDispatch();
  const { businesses, isFetching } = useSelector((state) => state.business);
  const getUserType = () => {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (auth != null) {
      return auth.user.type;
    }
  };

  useEffect(() => {
    props.getBusinesses();
    props.getCustomers();
    props.getEmployees(props.auth.uid);
    props.getRoutes();
    dispatch(fetchBusiness(props?.auth?.uid));
  }, []);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body ">
            {getUserType() == "distributor" ? (
              <>
                <Row className="justify-content-center">
                  <Col lg="6" xl="5">
                    <Link to="/admin/businesses">
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Where To Buy Profile
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {businesses?.length}
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                                <i className="ni ni-building" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                  <Col lg="6" xl="5">
                    <Link to="/admin/employees">
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Employees
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {props.employees.length}
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                <i className="fas fa-chart-pie" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                </Row>

                <Row className="justify-content-center mt-3">
                  <Col lg="6" xl="5">
                    <Link to="/admin/account-management">
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Account Management
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {props.customers.length}
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                                <i className="fas fa-users" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>

                  <Col lg="6" xl="5">
                    <Link to="/admin/routes">
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Route Management
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {props.routes.length}
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                <i class="fas fa-map-marker-alt"></i>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                </Row>
              </>
            ) : (
              <Row className="justify-content-center">
                <Col lg="6" xl="5">
                  <Link to="/admin/profile">
                    <Card className="card-stats mb-4 mb-xl-0 mt-xl-4">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h4"
                              className="d-flex h-100 align-items-center text-uppercase text-muted mb-0"
                            >
                              Update profile
                            </CardTitle>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                              <i className="fas fa-user" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg="6" xl="5">
                  <Link to="/admin/businesses">
                    <Card className="card-stats mb-4 mb-xl-0 mt-xl-4">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Where To Buy Profile
                            </CardTitle>
                            {isFetching ? (
                              <div>
                                {[...Array(3)].map(() => (
                                  <Spinner
                                    color="primary"
                                    type="grow"
                                    size="sm"
                                    className="mr-1"
                                  />
                                ))}
                              </div>
                            ) : (
                              <span className="h2 font-weight-bold mb-0">
                                {businesses?.length}
                              </span>
                            )}
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                              <i className="ni ni-building" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};
export const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    routes: state.mdp.routes,
    customers: state.mdp.customers,
    businesses: state.mdp.businesses,
    employees: state.mdp.employees,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    getCustomers: () => dispatch(getCustomers()),
    getRoutes: () => dispatch(getRoutes()),
    getBusinesses: () => dispatch(getBusinesses()),
    getEmployees: (id) => dispatch(getEmployees(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
