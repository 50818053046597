/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState, useEffect, useRef } from 'react';
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from 'react-copy-to-clipboard';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  Progress,
  DropdownItem,
  DropdownMenu,
  Media,
  Badge,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  ModalFooter,
  Dropdown,
} from 'reactstrap';

// core components
import * as XLSX from 'xlsx';
import { CSVLink, CSVDownload } from 'react-csv';
import Header from 'components/Headers/Header.js';
import DefaultHeader from 'components/Headers/DefaultHeader';
import { connect } from 'react-redux';
import {
  getCustomers,
  addCustomer,
  editCustomer,
  deleteCustomer,
} from '../../store/actions/customers';
import { getEmployees } from '../../store/actions/employees';
import { getRoutes } from '../../store/actions/routeInfo';

const AccountManagement = (props) => {
  const [cBusinessName, setCBusinessName] = useState('');
  const [shippingAddress, setShippingAddress] = useState('');
  const [primaryContact, setPrimaryContact] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [email, setEmail] = useState('');
  const [route, setRoute] = useState('');
  const [routeStop, setRouteStop] = useState('');
  const [routeFrequency, setRouteFrequency] = useState('Weekly');
  const [copiedText, setCopiedText] = useState();
  const [modal, setModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [ids, setIds] = useState('');
  const [editObject, setEditObject] = useState({});
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [accountManager, setAccountManager] = useState('');
  const [cellPhone, setCellPhone] = useState('false');

  const [customersData, setCustomersData] = useState([]);

  const [value, setValue] = useState('');

  const [dropdownOpenManager, setDropdownOpenManager] = useState(false);
  const [dropdownOpenRoute, setDropdownOpenRoute] = useState(false);
  const [selectedManager, setSelectedManager] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState([]);

  const filterManager = () => setDropdownOpenManager((prevState) => !prevState);
  const filterRoute = () => setDropdownOpenRoute((prevState) => !prevState);

  const [selectedRouteName, setSelectedRouteName] = useState('Route');
  const [selectedManagerName, setSelectedManagerName] = useState('Manager');

  const returnedFiltered = () => {
    if (selectedManager != '') {
      return props.customers.filter(
        (cus) => cus.accountManager == selectedManager
      );
    } else if (selectedRoute != '') {
      return props.customers.filter((cus) => cus.route == selectedRoute);
    } else {
      return props.customers;
    }

    console.log('Filter Data:', data);
  };

  const handleSelect = (e) => {
    console.log(e);
    setValue(e);
  };
  const csvData = [
    [
      'customer_businessName',
      'email',
      'phoneNo',
      'primaryContact',
      'shippingAddress',
      'route',
      'routeStop',
      'routeFrequency',
      'accountManager',
    ],
    [
      'Demo',
      'demo@gmail.com',
      '92345458334',
      '92312384554',
      'Address',
      'N/A',
      '22',
      'Call',
      'N/A',
    ],
  ];

  const inputFile = useRef(null);
  console.log(accountManager);
  const onButtonCLick = () => {
    inputFile.current.click();
  };
  // process CSV data
  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columns = headers.map((c) => ({
      name: c,
      selector: c,
    }));

    setData(list);
    setColumns(columns);
    // console.log(list[0]);
    let obj = list[0];
    if (obj.customer_businessName == '') {
      alert('business name is empty');
    } else if (obj.shippingAddress == '') {
      alert('shippingAddress is empty');
    } else if (obj.primaryContact == '') {
      alert('primaryContact is empty');
    } else if (obj.phoneNo == '') {
      alert('phoneNo is empty');
    } else if (obj.email == '') {
      alert('email is empty');
    } else if (obj.route == '') {
      alert('route not selected');
    } else if (obj.routeStop == '') {
      alert('routeStop is empty');
    } else {
      props.addCustomer(obj);
      alert('imported successfully');
    }
  };

  // handle file upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsBinaryString(file);
  };

  const toggle = () => {
    setModal(!modal);
  };
  const createToggle = () => {
    setCreateModal(!createModal);
  };
  const editToggle = () => {
    setEditModal(!editModal);
  };
  const getFullName = (id) => {
    // props.employees.find(
    //   (emp) => emp.id == cust.accountManager
    // ).firstName
    let data = props.employees.find((emp) => emp.id == id);
    let fullName = data.firstName + ' ' + data.lastName;
    return fullName;
  };

  useEffect(() => {
    if (props.routes.length < 0) {
      props.getRoutes();
    }
    if (props.customers.length < 0) {
      props.getCustomers();
    }
    if (props.employees.length < 0) {
      props.getEmployees(props.auth.uid);
    }

    // console.log('Customers data:', customersData);
    const route = props.routes;
    const user = props.employees;
  }, []);
  return (
    <>
      <DefaultHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent ">
                <div className="d-flex">
                  <h3 className="mb-0">Account Management </h3>

                  <Button
                    onClick={createToggle}
                    color="primary"
                    className="ml-auto"
                  >
                    Add Customer
                  </Button>
                  <Button
                    color="primary"
                    onClick={onButtonCLick}
                    className="float-right"
                  >
                    <input
                      type="file"
                      name="file"
                      id="file"
                      ref={inputFile}
                      style={{ display: 'none' }}
                      accept=".csv,.xlsx,.xls"
                      onChange={handleFileUpload}
                    />
                    Import Customer
                  </Button>
                </div>

                <div className="d-flex row">
                  <Col sm="3">
                    <Dropdown
                      isOpen={dropdownOpenManager}
                      toggle={filterManager}
                    >
                      <DropdownToggle caret>
                        {selectedManagerName}
                      </DropdownToggle>
                      <DropdownMenu container="body">
                        {props.employees &&
                          props.employees.map((emp) => (
                            <DropdownItem
                              onClick={(e) => {
                                let fullName =
                                  emp.firstName + ' ' + emp.lastName;
                                setSelectedRoute('');
                                setSelectedManagerName(fullName);
                                setSelectedManager(emp.id);
                                setSelectedRouteName('Route');
                              }}
                            >
                              {emp.firstName} {emp.lastName}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </Dropdown>
                    {/* <Input type="select" id="routemanager" name="routemanager">
                      <option value="default">Filter Manager</option>
                      {props.employees &&
                        props.employees.map((emp) => (
                          <option key={emp.id}>
                            {emp.firstName} {emp.lastName}
                          </option>
                        ))}
                    </Input> */}
                  </Col>

                  <Col>
                    <Dropdown isOpen={dropdownOpenRoute} toggle={filterRoute}>
                      <DropdownToggle caret>{selectedRouteName}</DropdownToggle>
                      <DropdownMenu container="body">
                        {props.routes &&
                          props.routes.map((route) => (
                            <DropdownItem
                              value={route.routeName}
                              onClick={(e) => {
                                setSelectedManager('');
                                setSelectedRouteName(route.routeName);
                                setSelectedRoute(route.id);
                                setSelectedManagerName('Manager');
                              }}
                            >
                              {route.routeName}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </Dropdown>

                    {/* <Input type="select" id="route" name="route">
                      <option value="default">Filter Route</option>
                      {props.routes &&
                        props.routes.map((route) => (
                          <option key={route.id}>{route.routeName}</option>
                        ))}
                    </Input> */}
                  </Col>

                  <Col className=" mt-2">
                    <Button
                      className="btn btn-sm btn-success"
                      onClick={(e) => {
                        setSelectedRoute('');
                        setSelectedManager('');
                        setSelectedManagerName('Filter Manager');
                        setSelectedRouteName('Filter Route');
                      }}
                    >
                      Clear Filter
                    </Button>
                  </Col>
                  <CSVLink data={csvData} className="ml-auto">
                    <Badge
                      style={{ marginTop: '15px', marginLeft: 'auto' }}
                      color="primary"
                    >
                      Download Acceptable CSV format
                    </Badge>
                  </CSVLink>
                </div>
              </CardHeader>

              {/* Create Customer Model */}
              <Modal isOpen={createModal} toggle={createToggle}>
                <ModalHeader toggle={createToggle}>Create Customer</ModalHeader>
                <ModalBody>
                  <div>
                    <Label>Customer Business Name</Label>
                    <Input
                      type="text"
                      name="businessName"
                      onChange={(e) => setCBusinessName(e.target.value)}
                      placeholder="Enter Customer Business Name"
                    />
                    <Label className="mt-2">Shipping Address</Label>
                    <Input
                      type="text"
                      name="shippingAddress"
                      onChange={(e) => setShippingAddress(e.target.value)}
                      placeholder="Enter Shipping Address"
                    />
                    <Label className="mt-2">Primary Contact</Label>
                    <Input
                      type="text"
                      name="contact"
                      onChange={(e) => setPrimaryContact(e.target.value)}
                      placeholder="Enter Contact No"
                    />
                    <Label className="mt-2">Phone Number</Label>
                    <Input
                      type="number"
                      name="phoneNo"
                      onChange={(e) => setPhoneNo(e.target.value)}
                      placeholder="Enter Phone Number"
                    />
                    <Label className="mt-2">Email Address</Label>
                    <Input
                      type="email"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Email Address"
                    />
                    <Label className="mt-2">Route </Label>
                    <Input
                      type="select"
                      id="route"
                      onChange={(e) => setRoute(e.target.value)}
                      name="route"
                    >
                      <option value="default">Select Route</option>
                      {props.routes &&
                        props.routes.map((rout) => (
                          <option key={rout.id} value={rout.id}>
                            {rout.routeName}
                          </option>
                        ))}
                    </Input>
                    <Label className="mt-2">Route Stop No</Label>
                    <Input
                      type="number"
                      name="routeStopNo"
                      onChange={(e) => setRouteStop(e.target.value)}
                      placeholder="Enter Route Stop No"
                    />
                    <Label className="mt-2">Route Frequency </Label>
                    <Input
                      type="select"
                      id="routefrequency"
                      name="routefrequency"
                      onChange={(e) => setRouteFrequency(e.target.value)}
                    >
                      <option value="Weekly">Weekly</option>
                      <option value="Bi-Weekly">Bi-Weekly</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Call">Call</option>
                    </Input>

                    <Label className="mt-2">Account Manager </Label>
                    <Input
                      type="select"
                      id="accountmanager"
                      onChange={(e) => setAccountManager(e.target.value)}
                      name="accountmanager"
                    >
                      <option value="default">Select Account Manager</option>
                      {props.employees &&
                        props.employees.map((emp) => (
                          <option key={emp.id} value={emp.id}>
                            {emp.firstName} {emp.lastName}
                          </option>
                        ))}
                    </Input>
                    <Label check className="mt-2">
                      Cell Phone
                      <Input
                        type="checkbox"
                        name="cellphone"
                        onChange={(e) => setCellPhone(e.target.checked)}
                        className="ml-2 mt-2"
                      />
                    </Label>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      let obj = {
                        customer_businessName: cBusinessName,
                        shippingAddress,
                        primaryContact,
                        phoneNo,
                        email,
                        route,
                        routeStop,
                        routeFrequency,
                        accountManager,
                        cellPhone,
                      };

                      if (cBusinessName == '') {
                        alert('business name is empty');
                      } else if (shippingAddress == '') {
                        alert('shippingAddress is empty');
                      } else if (primaryContact == '') {
                        alert('primaryContact is empty');
                      } else if (phoneNo == '') {
                        alert('phoneNo is empty');
                      } else if (email == '') {
                        alert('email is empty');
                      } else if (route == '') {
                        alert('route not selected');
                      } else if (routeStop == '') {
                        alert('routeStop is empty');
                      } else {
                        setSelectedManager('');
                        setSelectedRoute('');
                        props.addCustomer(obj);
                      }
                      createToggle();
                    }}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </Modal>
              {/* Ended */}
              {/* Edit Customer  Model */}
              <Modal isOpen={editModal} toggle={editToggle}>
                <ModalHeader toggle={editToggle}>Edit Customer</ModalHeader>
                <ModalBody>
                  <div>
                    <Label>Customer Business Name</Label>
                    <Input
                      type="text"
                      name="businessName"
                      onChange={(e) => setCBusinessName(e.target.value)}
                      defaultValue={cBusinessName}
                    />
                    <Label className="mt-2">Shipping Address</Label>
                    <Input
                      type="text"
                      name="shippingAddress"
                      onChange={(e) => setShippingAddress(e.target.value)}
                      defaultValue={shippingAddress}
                    />
                    <Label className="mt-2">Primary Contact</Label>
                    <Input
                      type="text"
                      name="contact"
                      onChange={(e) => setPrimaryContact(e.target.value)}
                      defaultValue={primaryContact}
                    />
                    <Label className="mt-2">Phone Number</Label>
                    <Input
                      type="text"
                      name="phoneNo"
                      onChange={(e) => setPhoneNo(e.target.value)}
                      defaultValue={phoneNo}
                    />
                    <Label className="mt-2">Email Address</Label>
                    <Input
                      type="email"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      defaultValue={email}
                    />
                    <Label className="mt-2">Route </Label>
                    <Input
                      type="select"
                      id="route"
                      name="route"
                      defaultValue={route}
                      onChange={(e) => setRoute(e.target.value)}
                    >
                      {props.routes &&
                        props.routes.map((rout) => (
                          <option key={rout.id} value={rout.id}>
                            {rout.routeName}
                          </option>
                        ))}
                    </Input>
                    <Label className="mt-2">Route Stop No</Label>
                    <Input
                      type="number"
                      name="routeStopNo"
                      onChange={(e) => setRouteStop(e.target.value)}
                      defaultValue={routeStop}
                    />
                    <Label className="mt-2">Route Frequency </Label>
                    <Input
                      type="select"
                      id="routefrequency"
                      name="routefrequency"
                      onChange={(e) => setRouteFrequency(e.target.value)}
                      defaultValue={routeFrequency}
                    >
                      <option value="Weekly">Weekly</option>
                      <option value="Bi-Weekly">Bi-Weekly</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Call">Call</option>
                    </Input>

                    <Label className="mt-2">Account Manager </Label>
                    <Input
                      type="select"
                      id="accountmanager"
                      onChange={(e) => setAccountManager(e.target.value)}
                      name="accountmanager"
                      defaultValue={accountManager}
                    >
                      <option value="default">Select Account Manager</option>
                      {props.employees &&
                        props.employees.map((emp) => (
                          <option key={emp.id} value={emp.id}>
                            {emp.firstName} {emp.lastName}
                          </option>
                        ))}
                    </Input>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      let obj = {
                        customer_businessName: cBusinessName,
                        shippingAddress,
                        primaryContact,
                        phoneNo,
                        email,
                        route,
                        routeStop,
                        routeFrequency,
                        accountManager,
                      };
                      props.editCustomer(ids, obj);
                      setCBusinessName('');
                      setShippingAddress('');
                      setPrimaryContact('');
                      setPhoneNo('');
                      setEmail('');
                      setRoute('Route 1');
                      setRouteStop('');
                      setRouteFrequency('Weekly');
                      setIds('');
                      setAccountManager('');
                      editToggle();
                    }}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </Modal>
              {/* Ended */}
              {/* Delete Modal */}
              <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Delete Customer</ModalHeader>
                <ModalBody>Are you sure you want to delete ? </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      props.deleteCustomer(ids);
                      setIds('');
                      toggle();
                    }}
                  >
                    Confirm
                  </Button>
                  <Button color="danger" onClick={toggle}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
              {/* Ended */}

              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Customer Business Name</th>
                      <th scope="col">Shipping Address</th>
                      <th scope="col">Primary Contact</th>
                      <th scope="col">Phone No</th>
                      <th scope="col">Email Address</th>
                      <th scope="col">Route</th>
                      <th scope="col">Rout Stop Number</th>
                      <th scope="col">Stop Frequency</th>
                      <th scope="col">Account Manager</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.customers &&
                      returnedFiltered().map((cust) => (
                        <tr key={cust.id}>
                          <th scope="row">
                            <span className="mb-0 text-sm">
                              {cust.customer_businessName}
                            </span>
                          </th>
                          <td>{cust.shippingAddress}</td>
                          <td>
                            <span className="mb-0 text-sm">
                              {cust.primaryContact}
                            </span>
                          </td>
                          <td>{cust.phoneNo}</td>
                          <td>{cust.email}</td>
                          <td>
                            {props.routes.find((rt) => rt.id == cust.route) !=
                              null
                              ? props.routes.find((rt) => rt.id == cust.route)
                                .routeName
                              : 'N/A'}
                          </td>
                          <td>{cust.routeStop}</td>
                          <td>{cust.routeFrequency}</td>

                          <td>
                            {props.employees.find(
                              (emp) => emp.id == cust.accountManager
                            ) != null
                              ? getFullName(cust.accountManager)
                              : 'N/A'}
                          </td>

                          <td>
                            <div className="d-flex align-items-center">
                              <Button
                                onClick={() => {
                                  let obj = cust;

                                  setCBusinessName(obj.customer_businessName);
                                  setShippingAddress(obj.shippingAddress);
                                  setPrimaryContact(obj.primaryContact);
                                  setPhoneNo(obj.phoneNo);
                                  setEmail(obj.email);
                                  setRoute(obj.route);
                                  setRouteStop(obj.routeStop);
                                  setRouteFrequency(obj.routeFrequency);
                                  setIds(obj.id);
                                  setAccountManager(obj.accountManager);
                                  console.log('route', route, 'id', ids);
                                  editToggle();
                                }}
                                color="secondary"
                                size="sm"
                              >
                                <i className="fas fa-edit" />
                              </Button>
                              <Button
                                onClick={() => {
                                  let obj = cust.id;
                                  setIds(obj);
                                  toggle();
                                }}
                                color="danger"
                                size="sm"
                              >
                                <i className="fas fa-trash" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            {/* <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Customer Business Name</th>
                      <th scope="col">Shipping Address</th>
                      <th scope="col">Primary Contact</th>
                      <th scope="col">Phone No</th>
                      <th scope="col">Email Address</th>
                      <th scope="col">Route</th>
                      <th scope="col">Rout Stop Number</th>
                      <th scope="col">Stop Frequency</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.customers &&
                      props.customers.map((cust) => (
                        <tr key={cust.id}>
                          <th scope="row">
                            <span className="mb-0 text-sm">
                              {cust.customer_businessName}
                            </span>
                          </th>
                          <td>{cust.shippingAddress}</td>
                          <td>
                            <span className="mb-0 text-sm">
                              {cust.primaryContact}
                            </span>
                          </td>
                          <td>{cust.phoneNo}</td>
                          <td>{cust.email}</td>
                          <td>
                            {props.routes.find((rt) => rt.id == cust.route) !=
                            null
                              ? props.routes.find((rt) => rt.id == cust.route)
                                  .routeName
                              : 'N/A'}
                          </td>
                          <td>{cust.routeStop}</td>
                          <td>{cust.routeFrequency}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Button
                                onClick={() => {
                                  let obj = cust;

                                  setCBusinessName(obj.customer_businessName);
                                  setShippingAddress(obj.shippingAddress);
                                  setPrimaryContact(obj.primaryContact);
                                  setPhoneNo(obj.phoneNo);
                                  setEmail(obj.email);
                                  setRoute(obj.route);
                                  setRouteStop(obj.routeStop);
                                  setRouteFrequency(obj.routeFrequency);
                                  setIds(obj.id);
                                  console.log('route', route, 'id', ids);
                                  editToggle();
                                }}
                                color="secondary"
                                size="sm"
                              >
                                <i className="fas fa-edit" />
                              </Button>
                              <Button
                                onClick={() => {
                                  let obj = cust.id;
                                  setIds(obj);
                                  toggle();
                                }}
                                color="danger"
                                size="sm"
                              >
                                <i className="fas fa-trash" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card> */}
          </div>
        </Row>
      </Container>
    </>
  );
};
export const mapStateToProps = (state) => {
  return {
    customers: state.mdp.customers,
    routes: state.mdp.routes,
    employees: state.mdp.employees,
    auth: state.auth
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    getRoutes: () => dispatch(getRoutes()),
    getCustomers: () => dispatch(getCustomers()),
    addCustomer: (object) => dispatch(addCustomer(object)),
    editCustomer: (id, object) => dispatch(editCustomer(id, object)),
    deleteCustomer: (id) => dispatch(deleteCustomer(id)),
    getEmployees: (id) => dispatch(getEmployees(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountManagement);
