const initState = {
  brands: [],
  isAdding: false,
  isFetching: false,
  isUpdating: false,
  isDeleting: false,
  actionLoading: false,
};

const brandReducer = (state = initState, action) => {
  switch (action.type) {
    case "FETCH_BRAND_PENDING":
      return {
        ...state,
        isFetching: true,
      };
    case "FETCH_BRAND_SUCCESS":
      return {
        ...state,
        brands: action.brands,
        isFetching: false,
      };
    case "FETCH_BRAND_REJECTED":
      return {
        ...state,
        isFetching: false,
      };
    case "ADD_BRAND_PENDING":
      return {
        ...state,
        actionLoading: true,
      };
    case "ADD_BRAND_SUCCESS":
    case "ADD_BRAND_REJECTED":
      return {
        ...state,
        actionLoading: false,
      };
    case "UPDATE_BRAND_PENDING":
      return {
        ...state,
        actionLoading: true,
      };
    case "UPDATE_BRAND_SUCCESS":
    case "UPDATE_BRAND_REJECTED":
      return {
        ...state,
        actionLoading: false,
      };
    case "DELETE_BRAND_PENDING":
      return {
        ...state,
        actionLoading: true,
      };
    case "DELETE_BRAND_SUCCESS":
    case "DELETE_BRAND_REJECTED":
      return {
        ...state,
        actionLoading: false,
      };
    default:
      return state;
  }
};

export default brandReducer;
