import { combineReducers } from 'redux';
import allReducers from './allReducers';
import authReducer from "./authReducer"
import generalReducer from './generalReducers';
import businessReducer from './businessReducer';
import brandReducer from './brandReducer';

const rootReducer = combineReducers({
  mdp: allReducers,
  auth: authReducer,
  business: businessReducer,
  brand: brandReducer,
  general: generalReducer,
});
export default rootReducer;
