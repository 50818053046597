import DefaultHeader from 'components/Headers/DefaultHeader'
import React, { useEffect, useState } from 'react'
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { updateProfile } from "../../store/actions/authActions"
import { toast } from "react-toastify"
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from "@stripe/react-stripe-js";
import { Container, Row, Col, FormGroup, Label, Input, Button, Form, Card, CardHeader, CardBody, Spinner } from 'reactstrap';
import moment from 'moment';
import { fetchProduct } from 'store/actions/generalActions';
import { fetchPromos } from 'store/actions/generalActions';
let stripePromise = loadStripe(
    `pk_test_51JoICRBImLgaCS3YcTNE5uwLMqghVUIfSolQi3soF1z9rVFgKg4HXAAbFaqTw5epT6pu1LyPP8chq2ss9YIf5gqb00XdyMeIJk`
);

function Payments() {
    let general = useSelector(state => state.general)
    let { promos } = useSelector(state => state.general)
    // const [price, setPrice] = useState(Object.keys(general.price).length > 1 && general.price);
    const [subsDetails, setSubDetails] = useState({})
    const [promo, setPromo] = useState("")
    const [resubPromo, setResubPromo] = useState("")
    const [loading, setLoading] = useState(false)
    let auth = useSelector(state => state.auth)
    let dispatch = useDispatch()

    const retrieveSubscription = () => {
        let body = {
            subscription_id: auth.user.subscription_id
        }
        // axios.get("https://us-central1-mdp-platform.cloudfunctions.net/fetchProduct").then(res => setPrice(res.data.prices.data[0]))
        if (auth.user.subscription_id) {
            axios.post("https://us-central1-mdp-platform.cloudfunctions.net/retrieveSubscription", body).then(res => setSubDetails(res.data.subscription))
        }
    }
    useEffect(() => {
        if (Object.keys(general.price).length == 0) {
            dispatch(fetchProduct())
        }
        dispatch(fetchPromos())
    }, [])
    useEffect(() => {
        retrieveSubscription()
    }, [auth])

    let today = moment()
    let expiry = moment.unix(subsDetails.current_period_end);
    let differ = expiry.diff(today, 'days')
    return (
        <>
            <DefaultHeader />
            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent d-flex">
                                <h3 className="mb-0">Payments</h3>
                            </CardHeader>
                            <CardBody>

                                <Row className={`${auth.user.customer_id == "" && auth.user.subscription_id == "" ? "d-md-flex d-block" : "d-none"}`}>
                                    <Col>
                                        <h2>Amount to Pay</h2>
                                        <h1 className="text-center mt-5">${Object.keys(general.price).length > 1 && general.price.unit_amount / 100}</h1>
                                    </Col>
                                    <Col>
                                        <div >
                                            <Elements stripe={stripePromise} >
                                                <ElementsConsumer>
                                                    {({ elements, stripe }) => (
                                                        <Form
                                                            onSubmit={async (e) => {
                                                                e.preventDefault();
                                                                setLoading(true)
                                                                const cardElement = await elements.getElement(
                                                                    CardNumberElement
                                                                );
                                                                const cardElement2 = await elements.getElement(
                                                                    CardExpiryElement
                                                                );
                                                                const cardElement3 = await elements.getElement(
                                                                    CardCvcElement
                                                                );

                                                                const { error, paymentMethod } =
                                                                    await stripe.createPaymentMethod({
                                                                        type: "card",
                                                                        card: cardElement,
                                                                        card: cardElement2,
                                                                        card: cardElement3,
                                                                    });
                                                                if (error) {
                                                                    setLoading(false)
                                                                    alert("Card Details not Added");
                                                                } else {
                                                                    if (promo != "") {
                                                                        let coupon = promos.find(pro => pro.code == promo)
                                                                        // console.log('coupon', coupon)
                                                                        if (coupon) {
                                                                            // console.log("[payment Method]", paymentMethod)
                                                                            const result = await stripe.createToken(cardElement);
                                                                            // console.log("result", result)
                                                                            if (result.error) {
                                                                                alert(result.error.message);
                                                                                // console.log("error result", result)
                                                                                setLoading(false)
                                                                                return;
                                                                            }
                                                                            let body = {
                                                                                coupon_id: coupon.coupon.id,
                                                                                token_id: result.token.id,
                                                                                email: "demo@gmail.com",
                                                                                price_id: Object.keys(general.price).length > 1 && general.price.id
                                                                            }
                                                                            // console.log('body', body)
                                                                            await axios.post("https://us-central1-mdp-platform.cloudfunctions.net/getSubscription", body).then(res => {
                                                                                let obj = {
                                                                                    customer_id: res.data.customer.id,
                                                                                    subscription_id: res.data.subscription.id
                                                                                }
                                                                                dispatch(updateProfile(obj, auth.uid))
                                                                                setLoading(false)
                                                                                toast.success("Subscribed Successfully")
                                                                            })
                                                                        } else {
                                                                            toast.warning("Invalid Promo Code")
                                                                            setLoading(false)
                                                                        }
                                                                    } else {
                                                                        // console.log("[payment Method]", paymentMethod)
                                                                        const result = await stripe.createToken(cardElement);
                                                                        // console.log("result", result)
                                                                        if (result.error) {
                                                                            alert(result.error.message);
                                                                            // console.log("error result", result)
                                                                            setLoading(false)
                                                                            return;
                                                                        }
                                                                        let body = {
                                                                            coupon_id: "",
                                                                            token_id: result.token.id,
                                                                            email: "demo@gmail.com",
                                                                            price_id: Object.keys(general.price).length > 1 && general.price.id
                                                                        }
                                                                        // console.log('body', body)
                                                                        await axios.post("https://us-central1-mdp-platform.cloudfunctions.net/getSubscription", body).then(res => {
                                                                            let obj = {
                                                                                customer_id: res.data.customer.id,
                                                                                subscription_id: res.data.subscription.id
                                                                            }
                                                                            dispatch(updateProfile(obj, auth.uid))
                                                                            setLoading(false)
                                                                            toast.success("Subscribed Successfully")
                                                                        })
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <Label class="offerheading text-center mb-2">
                                                                <h3 class="text-center">
                                                                    <b>Card Detail</b>
                                                                </h3>
                                                            </Label>

                                                            <FormGroup>
                                                                <Label>Card number</Label>
                                                                <div
                                                                    className="form-control mt-2 d-flex"
                                                                    style={{
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    <i className="fa fa-credit-card"></i>
                                                                    <div style={{ flexBasis: "90%" }}>
                                                                        <CardNumberElement
                                                                            required
                                                                            options={{
                                                                                placeholder: "1234 1234 1234 1234",
                                                                                style: {
                                                                                    base: {
                                                                                        // backgroundColor: "#232733",
                                                                                        fontSize: "16px",
                                                                                    },
                                                                                    invalid: {
                                                                                        color: "#9e2146",
                                                                                    },
                                                                                },
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label>Expiry Date</Label>
                                                                <div
                                                                    className="form-control mt-2 d-flex"
                                                                    style={{
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    <i className="fa fa-calendar"></i>
                                                                    <div style={{ flexBasis: "90%" }}>
                                                                        <CardExpiryElement
                                                                            required
                                                                            options={{
                                                                                placeholder: "MM/YY",
                                                                                style: {
                                                                                    base: {
                                                                                        fontSize: "16px",
                                                                                    },
                                                                                    invalid: {
                                                                                        color: "#9e2146",
                                                                                    },
                                                                                },
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label>CVC/CVV</Label>
                                                                <div
                                                                    className="form-control mt-2 d-flex"
                                                                    style={{
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    <div style={{ flexBasis: "80%" }}>
                                                                        <CardCvcElement
                                                                            required
                                                                            options={{
                                                                                placeholder: "...",
                                                                                style: {
                                                                                    base: {
                                                                                        fontSize: "16px",
                                                                                    },
                                                                                    invalid: {
                                                                                        color: "#9e2146",
                                                                                    },
                                                                                },
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label>PROMO CODE</Label>
                                                                <Input
                                                                    placeholder='PROMO CODE'
                                                                    type="text"
                                                                    value={promo}
                                                                    onChange={e => setPromo(e.target.value)}
                                                                />
                                                            </FormGroup>
                                                            <Button color="primary" className="w-100">
                                                                {
                                                                    loading ? <Spinner size="sm" /> : "Subscribe"
                                                                }
                                                            </Button>
                                                        </Form>
                                                    )}
                                                </ElementsConsumer>
                                            </Elements>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={`${Object.keys(subsDetails).length > 1 && subsDetails.canceled_at == null ? "d-md-flex d-block" : "d-none"} `}>
                                    <Col>
                                        <h4 className="mt-2">You have subscribed our premium package</h4>
                                        <h4>Current Billing Cycle ends at {moment.unix(subsDetails.current_period_end).format("YYYY-MM-DD")}</h4>
                                    </Col>
                                    <Col>
                                        <Button className="w-100" color="warning" onClick={async () => {
                                            setLoading(true)
                                            let body = {
                                                subscription_id: auth.user.subscription_id
                                            }
                                            await axios.post("https://us-central1-mdp-platform.cloudfunctions.net/cancelSubscription", body).then(res => {
                                                if (res.data.success) {
                                                    // let obj = {
                                                    //     subscription_id: ""
                                                    // }
                                                    // dispatch(updateProfile(obj, auth.uid))
                                                    toast.success("Subscription Canceled Successfully")
                                                    setLoading(false)
                                                }
                                            })
                                            retrieveSubscription()
                                        }}>
                                            {
                                                loading ? <Spinner size="sm" /> : "Unsubscribe"
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className={`${Object.keys(subsDetails).length > 1 && subsDetails.canceled_at != null ? "d-md-flex d-block" : "d-none"} `}>
                                    <Col md="6">
                                        {
                                            differ > 0
                                                ?
                                                <>
                                                    <h4>You can Use this platform till {moment.unix(subsDetails.current_period_end).format("YYYY-MM-DD")}</h4>
                                                    <h4 className="mt-2">You can Subscribe again in {differ} days</h4>
                                                </>
                                                :
                                                <>
                                                    <h4>You subscription has ended, you can't use this platform anymore.</h4>
                                                    <h4 className="mt-2">You can Subscribe Now</h4>
                                                </>
                                        }
                                    </Col>
                                    <Col md="6">
                                        <Input
                                            type="text"
                                            placeholder='PROMO CODE'
                                            value={resubPromo}
                                            onChange={e => setResubPromo(e.target.value)}
                                        />
                                        <br />
                                        <Button className="w-100" color="primary"
                                            // disabled={differ >= 0}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setLoading(true)
                                                if (resubPromo != "") {
                                                    let coupon = promos.find(pro => pro.code == resubPromo)
                                                    if (coupon) {
                                                        let body = {
                                                            coupon_id: coupon.coupon.id,
                                                            customer_id: auth.user.customer_id,
                                                            price_id: Object.keys(general.price).length > 1 && general.price.id
                                                        }
                                                        axios.post("https://us-central1-mdp-platform.cloudfunctions.net/subscribeAgain", body).then(res => {
                                                            let obj = {
                                                                subscription_id: res.data.subscription.id
                                                            }
                                                            dispatch(updateProfile(obj, auth.uid))
                                                            setLoading(false)
                                                            toast.success("Subscribed Successfully")
                                                        })
                                                    } else {
                                                        toast.warning("Invalid Promo!")
                                                        setLoading(false)
                                                    }
                                                } else {
                                                    let body = {
                                                        coupon_id: "",
                                                        customer_id: auth.user.customer_id,
                                                        price_id: Object.keys(general.price).length > 1 && general.price.id
                                                    }
                                                    axios.post("https://us-central1-mdp-platform.cloudfunctions.net/subscribeAgain", body).then(res => {
                                                        let obj = {
                                                            subscription_id: res.data.subscription.id
                                                        }
                                                        dispatch(updateProfile(obj, auth.uid))
                                                        setLoading(false)
                                                        toast.success("Subscribed Successfully")
                                                    })
                                                }
                                            }}>{
                                                loading ? <Spinner size="sm" /> : "Resubscribe"
                                            }</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default Payments
