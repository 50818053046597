import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Spinner } from "reactstrap";
import { loginAsAdmin } from "store/actions/authActions";

const SuperAdminLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (id) {
      const encryptedData = atob(id);
      const decodedData = JSON.parse(encryptedData);
      if (decodedData?.isAdmin) {
        dispatch(loginAsAdmin({
          uid: decodedData?.uid,
          onSuccess: () => {
            setLoader(false);
            history.push('/index')
          },
          onError: () => {
            setLoader(false);
            setError(true);
          },
        }))
      } else {
        setLoader(false);
        setError(true);
      }
    } else {
      setLoader(false);
      setError(true);
    }
  }, [id]);

  return (
    <div className="w-100 h-100vh d-flex align-items-center justify-content-center">
      {loader && (
        <div style={{ scale: '150%' }}>
          <Spinner color="primary" />
        </div>
      )}
      {(!loader && error) && (
        <div className="text-center">
          <h1 style={{ scale: '150%', fontWeight: 800 }}>404</h1>
          <h1>User Not Found</h1>
        </div>
      )}
    </div>
  );
}

export default SuperAdminLogin;