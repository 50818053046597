
import firebase from '../../firebase';
export const getRoutes = () =>
{
    console.log("in get employee");
  return (dispatch) =>
  {
     firebase.firestore().collection('routes').onSnapshot(query =>
  {
    var listData = [];
    query.forEach((item) => {
      listData.push({ id: item.id, ...item.data() });
    });
    console.log(listData);
    dispatch({
      type: 'GET_ROUTES',
      payload: listData,
    });
  })
  };
  
}

export const addRoute = (creds) =>
{  
        console.log('creds', creds);
        return (dispatch)=>{
          dispatch({
            type:'ADD_ROUTE'
          });
          firebase.firestore().collection('routes').add(creds)
    };
  
 
}

export const editRoute=(id,creds)=>{
  return(dispatch)=>{
   console.log(id,creds);
    firebase.firestore().collection('routes').doc(id).update({...creds});
   
     dispatch({
      type:'EDIT_ROUTE'
    });
  }
}
export const deleteRoute=(id)=>{
  console.log('id',id)
  return(dispatch)=>{
   
    firebase.firestore().collection('routes').doc(id).delete();
     dispatch({
      type:'DELETE_ROUTE'
    });
  }
}