import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from 'reactstrap';
import { forgetpassword } from 'store/actions/authActions';

function ForgetPassword() {
  const [email, setEmail] = useState('')
  let auth = useSelector(state => state.auth)
  let dispatch = useDispatch()
  return (
    <div className='d-flex justify-content-center align-items-center w-100 h-100vh'>
      <Col lg='5' md='7'>
        <Card className='bg-white shadow border-0'>
          <CardBody className='px-lg-5 py-lg-5'>
            <div className='text-center text-muted mb-4'>
              <h3>Reset Password</h3>
            </div>
            <Form onSubmit={e => {
              e.preventDefault();
              dispatch(forgetpassword(email))
            }}>
              <FormGroup className='mb-3'>
                <InputGroup className='input-group-alternative secondary-border'>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText className="secondary-bg">
                      <i className='ni ni-email-83' />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder='Email'
                    type='email'
                    autoComplete='new-email'
                    required
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className="pl-3"
                  />
                </InputGroup>
              </FormGroup>

              <div className='text-center'>
                <Button className='my-4' disabled={!email} color='primary' type='submit'>
                  {auth.passwordLoading ? <Spinner size="sm" /> : 'Reset Password'}
                </Button>
              </div>
            </Form>
            <div className="link-primary">
              <Link to='/auth/login'>
                <small>Back to Login</small>
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>
    </div>
  )
}

export default ForgetPassword
