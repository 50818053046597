import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Col,
  CardTitle,
} from "reactstrap";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useDispatch, useSelector } from "react-redux";
import WarehouseLocation from "components/WarehouseLocation";
import { fetchBusiness, deleteBusiness } from "store/actions/businessActions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DashboardCard from "components/Cards/DashboardCard";
import { fetchBrands } from "store/actions/brandActions";

const Businesses = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { businesses, isFetching, isDeleting } = useSelector(
    (state) => state.business
  );
  const { brands, isFetching: isFetchingBrands } = useSelector(
    (state) => state.brand
  );

  const [activeBusinessId, setActiveBusinessId] = useState(null);
  const [activeBusiness, setActiveBusiness] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);

  const [locationModal, setLocationModal] = useState(false);

  const locationModalToggler = () => {
    if (locationModal) {
      setEditMode(false);
    }
    setLocationModal(!locationModal);
  };

  const deleteModalToggler = () => {
    if (deleteModal) {
      setActiveBusinessId(null);
    }
    setDeleteModal(!deleteModal);
  };

  const initiateEditBusiness = (buisness) => {
    setEditMode(true);
    setActiveBusiness(buisness);
    locationModalToggler();
  };

  const handleDeleteBusiness = () => {
    if (activeBusinessId) {
      dispatch(
        deleteBusiness({
          id: activeBusinessId,
          onSuccess: () => {
            deleteModalToggler();
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(fetchBusiness(user?.id));
    dispatch(fetchBrands(user?.id));
  }, []);

  return (
    <>
      {user?.type === "manufacturer" && (
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <Row className="justify-content-center">
              <Col lg="6" xl="5">
                <Link to="/admin/brands">
                  <DashboardCard
                    title="Your Brands"
                    stat={brands?.length || 0}
                    icon="ni ni-atom"
                    color="success"
                    isLoading={isFetchingBrands}
                  />
                </Link>
              </Col>
              <Col lg="6" xl="5">
                <DashboardCard
                  title="Your Distributors"
                  stat="Coming Soon..."
                  icon="ni ni-building"
                  color="warning"
                  isLoading={false}
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}
      {user?.type === "distributor" && (
        <>
          <DefaultHeader />
          <Container className="mt--7" fluid>
            <Row>
              <div className="col">
                <Card className="shadow mb-5">
                  <CardHeader className="bg-transparent d-flex justify-content-between align-items-center">
                    <h3 className="mb-0">Where To Buy Profile</h3>
                    <Button
                      size="sm"
                      color="primary"
                      className="py-2 px-3"
                      onClick={locationModalToggler}
                    >
                      Add Location
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Location Name</th>
                          <th scope="col">Address</th>
                          <th scope="col">Phone Number</th>
                          <th scope="col">Website</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isFetching ? (
                          <tr>
                            <td colSpan="5" className="text-center py-5">
                              <Spinner size="md" color="primary" />
                            </td>
                          </tr>
                        ) : businesses?.length > 0 ? (
                          businesses.map((business) => (
                            <tr key={business.id}>
                              <th scope="row">
                                <span className="mb-0 text-sm">
                                  {business?.locationName}
                                </span>
                              </th>
                              <td>{business?.address}</td>
                              <td>{business?.phone}</td>
                              <td>
                                <span className="mb-0 text-sm">
                                  {business?.website}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <Button
                                    onClick={() =>
                                      initiateEditBusiness(business)
                                    }
                                    color="warning"
                                    size="sm"
                                  >
                                    <i className="fas fa-edit" />
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      setActiveBusinessId(business.id);
                                      deleteModalToggler();
                                    }}
                                    color="danger"
                                    size="sm"
                                  >
                                    <i className="fas fa-trash" />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center py-5">
                              <h4 className="font-weight-normal">
                                No Business
                              </h4>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </Container>
        </>
      )}
      <WarehouseLocation
        modal={locationModal}
        modalToggler={locationModalToggler}
        editMode={editMode}
        activeBusiness={activeBusiness}
      />
      <Modal isOpen={deleteModal} toggle={deleteModalToggler}>
        <ModalHeader toggle={deleteModalToggler}>Delete Business</ModalHeader>
        <ModalBody>Are you sure you want to delete ? </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={deleteModalToggler}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleDeleteBusiness}
            disabled={isDeleting}
          >
            Confirm
            {isDeleting && <Spinner size="sm" className="ml-2" />}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Businesses;
