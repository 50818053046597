import Index from "views/Index.js";
import AccountManagement from "views/examples/AccountManagement";
import Employees from "views/examples/Employees.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import RouteInfo from "views/examples/RouteInfo.js";
import Businesses from "views/examples/Businesses.js";
import Profile from "views/examples/Profile";
import Payments from "views/examples/Payments";
import ForgetPassword from "views/examples/ForgetPassword";
import QBIntegration from "views/examples/QBIntegration";
import Customers from "views/examples/Customers";
import SuperAdminLogin from "views/examples/SuperAdminLogin";
import Brands from "views/examples/Brands";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    for: "all",
  },
  {
    path: "/businesses",
    name: "Where To Buy Profile",
    icon: "ni ni-building text-blue",
    component: Businesses,
    layout: "/admin",
    for: "distributor, manufacturer",
  },
  {
    path: "/brands",
    name: "Your Brands",
    icon: "ni ni-shop text-success",
    menu: false,
    component: Brands,
    layout: "/admin",
    for: "manufacturer",
  },
  {
    path: "/employees",
    name: "Employees",
    icon: "ni ni-single-02 text-orange",
    component: Employees,
    layout: "/admin",
    for: "distributor",
  },
  {
    path: "/customers",
    name: "Customers",
    icon: "ni ni-single-02 text-orange",
    component: Customers,
    layout: "/admin",
    for: "distributor",
  },
  {
    path: "/account-management",
    name: "Account Management",
    icon: "ni ni-single-02 text-yellow",
    component: AccountManagement,
    layout: "/admin",
    for: "distributor",
  },
  {
    path: "/routes",
    name: "Route Management",
    icon: "ni ni-pin-3 text-orange",
    component: RouteInfo,
    layout: "/admin",
    for: "distributor",
  },
  {
    path: "/payment",
    name: "Payments",
    icon: "ni ni-credit-card text-orange",
    component: Payments,
    layout: "/admin",
    for: "distributor",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "ni ni-single-02 text-primary",
    component: Profile,
    layout: "/admin",
    for: "all",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    menu: false,
    for: "all",
    layout: "/auth",
  },
  {
    path: "/forget-password",
    name: "Forgot Password",
    icon: "ni ni-key-25 text-info",
    component: ForgetPassword,
    menu: false,
    for: "all",
    layout: "/auth",
  },
  {
    path: "/auth/login/:id",
    name: "Super Admin Login",
    icon: "ni ni-key-25 text-info",
    component: SuperAdminLogin,
    menu: false,
  },
];
export default routes;
