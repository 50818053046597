import firebase from 'firebase';
import 'firebase/firestore';

var firebaseConfig = {
  apiKey: 'AIzaSyAErlhgRv1fpXcclisIBlZOB6-b90-CKak',
  authDomain: 'mdp-platform.firebaseapp.com',
  projectId: 'mdp-platform',
  storageBucket: 'mdp-platform.appspot.com',
  messagingSenderId: '400435988530',
  appId: '1:400435988530:web:8d46012bdc00d52f03942b',
  measurementId: 'G-H0S3R5GPMC',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
db.settings({ timestampsInSnapshots: true });


export default firebase;
