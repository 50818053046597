const initState = {
    price: {},
    customerData: [],
    admin: JSON.parse(localStorage.getItem("admin_creds")),
    token: JSON.parse(localStorage.getItem("token")),
    promos: []
};

const generalReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_ADMIN':
            localStorage.setItem("admin_creds", JSON.stringify(action.payload))
            return {
                ...state,
                admin: action.payload
            };
        case 'FETCH_PRODUCT':
            return {
                ...state,
                price: action.payload
            };
        case 'FETCH_PROMOS':
            return {
                ...state,
                promos: action.payload
            };
        case "FETCH_CUSTOMER_DATA":
            return {
                ...state,
                customerData: action.payload
            }
        case "GET_TOKEN":
            localStorage.setItem("token", JSON.stringify(action.payload))
            return {
                ...state,
                token: action.payload
            }
        case "CLEAR_GENERAL":
            return {
                ...state,
                token: {},
                customerData: [],
                price: {}
            }
        default:
            return state;
    }
};

export default generalReducer;
