import firebase from '../../firebase';
import { toast } from 'react-toastify';

export const fetchBusiness = (uid) => {
  return (dispatch) => {
    dispatch({ type: 'FETCH_BUSINESS_PENDING' });

    let query = firebase
      .firestore()
      .collection('businesses')
      .where('createdBy', '==', uid)
      .orderBy('createdAt', 'desc');

    const unsubscribe = query.onSnapshot(
      (snapshot) => {
        const businesses = [];
        snapshot.forEach((business) => {
          businesses.push({ id: business.id, ...business.data() });
        });
        dispatch({ type: 'FETCH_BUSINESS_SUCCESS', businesses });
      },
      (error) => {
        dispatch({ type: 'FETCH_BUSINESS_REJECTED' });
        console.error('Error fetching users:', error);
      }
    );
    return unsubscribe;
  };
};

export const addBusiness = ({ uid, payload, onSuccess = () => { } }) => {
  return async (dispatch) => {
    dispatch({ type: 'ADD_BUSINESS_PENDING' });
    try {
      await firebase
        .firestore()
        .collection('businesses')
        .add({
          ...payload,
          createdBy: uid,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      onSuccess();
      toast.success("Business Added Successfully");
      dispatch({ type: 'ADD_BUSINESS_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'ADD_BUSINESS_REJECTED' });
      toast.error(err.message || 'An unknown error occured')
    }
  };
};

export const updateBusiness = ({ businessId, payload, onSuccess = () => { } }) => {
  return async (dispatch) => {
    dispatch({ type: 'UPDATE_BUSINESS_PENDING' });
    try {
      await firebase
        .firestore()
        .collection('businesses')
        .doc(businessId)
        .update({
          ...payload,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      onSuccess();
      toast.success("Account Updated Successfully");
      dispatch({ type: 'UPDATE_BUSINESS_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'UPDATE_BUSINESS_REJECTED' });
      toast.error(err.message || 'An unknown error occurred');
    }
  };
};

export const deleteBusiness = ({ id, onSuccess = () => { } }) => {
  return async (dispatch) => {
    dispatch({ type: 'DELETE_BUSINESS_PENDING' });
    try {
      await firebase
        .firestore()
        .collection('businesses')
        .doc(id)
        .delete();
      onSuccess();
      toast.success("Business Deleted Successfully");
      dispatch({ type: 'DELETE_BUSINESS_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'DELETE_BUSINESS_REJECTED' });
      toast.error(err.message || 'An unknown error occurred');
    }
  };
};
