import firebase from '../../firebase';
import { toast } from 'react-toastify'
export const getEmployees = (id) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection('users')
      .where("type", "==", "employee")
      .where("delete", "==", false)
      .where("distributer", "==", id)
      .onSnapshot((query) => {
        var listData = [];
        query.forEach((item) => {
          listData.push({ id: item.id, ...item.data() });
        });
        console.log(listData);
        dispatch({
          type: 'GET_EMPLOYEES',
          payload: listData,
        });
      });
  };
};

export const addEmployee = (obj) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_EMPLOYEE',
    });
    firebase
      .auth()
      .createUserWithEmailAndPassword(obj.email, obj.password)
      .then(data => {
        firebase
          .firestore()
          .collection('users')
          .doc(data.user.uid)
          .set({
            ...obj,
          })
          .then((res) => {

            dispatch({
              type: 'ADD_EMPLOYEE_END',
            });
            toast.success("Employee Account Created Successfully")
          })
          .catch((err) => {

            dispatch({
              type: 'ADD_EMPLOYEE_END',
            });
            toast.warning(err.message)
          });
      })
      .catch((err) => {

        dispatch({
          type: 'ADD_EMPLOYEE_END',
        });
        toast.warning(err.message)
      });
  };
};

export const editEmployee = (id, creds) => {
  return (dispatch) => {
    console.log(id, creds);
    firebase
      .firestore()
      .collection('users')
      .doc(id)
      .update({ ...creds });

    dispatch({
      type: 'EDIT_EMPLOYEE',
    });
  };
};
export const deleteEmployee = (id) => {
  console.log('id', id);
  return (dispatch) => {
    firebase.firestore().collection('users').doc(id).update({ delete: true });
    dispatch({
      type: 'DELETE_EMPLOYEE',
    });
  };
};
