import firebase from "../../firebase";
import { toast } from "react-toastify";

export const fetchBrands = (uid) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_BRAND_PENDING" });

    let query = firebase
      .firestore()
      .collection("brands")
      .where("createdBy", "==", uid)
      .orderBy("createdAt", "desc");

    const unsubscribe = query.onSnapshot(
      (snapshot) => {
        const brands = [];
        snapshot.forEach((brand) => {
          brands.push(brand.data());
        });
        dispatch({ type: "FETCH_BRAND_SUCCESS", brands });
      },
      (error) => {
        dispatch({ type: "FETCH_BRAND_REJECTED" });
        console.error("Error fetching users:", error);
      }
    );
    return unsubscribe;
  };
};

export const addBrand = ({ uid, logo, payload }) => {
  return async (dispatch) => {
    dispatch({ type: "ADD_BRAND_PENDING" });
    try {
      const docRef = firebase.firestore().collection("brands").doc();
      let logoUrl = "";
      if (logo) {
        const imageRef = firebase
          .storage()
          .ref()
          .child(`brandImages/${docRef?.id}/${logo?.name}`);
        const snapshot = await imageRef.put(logo);
        logoUrl = await snapshot.ref.getDownloadURL();
      }
      await docRef.set({
        id: docRef?.id,
        createdBy: uid,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        logo: {
          name: logo?.name ? logo?.name : '',
          url: logoUrl,
        },
        ...payload,
      });
      toast.success("Brand Added Successfully");
      dispatch({ type: "ADD_BRAND_SUCCESS" });
    } catch (err) {
      dispatch({ type: "ADD_BRAND_REJECTED" });
      toast.error(err.message || "An unknown error occured");
    }
  };
};

export const updateBrand = ({ brandId, logo, previousLogoUrl, payload }) => {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_BRAND_PENDING" });
    try {
      const isNewLogo = logo instanceof File;
      let newLogoUrl = "";
      if (isNewLogo) {
        if (previousLogoUrl) {
          const storageRef = firebase.storage().refFromURL(previousLogoUrl);
          await storageRef.delete();
        }
        const newImageRef = firebase
          .storage()
          .ref()
          .child(`brandImages/${brandId}/${logo?.name}`);
        const snapshot = await newImageRef.put(logo);
        newLogoUrl = await snapshot.ref.getDownloadURL();
      }
      await firebase
        .firestore()
        .collection("brands")
        .doc(brandId)
        .update({
          ...payload,
          logo: {
            name: logo?.name,
            url: isNewLogo ? newLogoUrl : logo?.url,
          },
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      toast.success("Brand Updated Successfully");
      dispatch({ type: "UPDATE_BRAND_SUCCESS" });
    } catch (err) {
      dispatch({ type: "UPDATE_BRAND_REJECTED" });
      toast.error(err.message || "An unknown error occurred");
    }
  };
};

export const deleteBrand = ({ id, logoUrl }) => {
  return async (dispatch) => {
    dispatch({ type: "DELETE_BRAND_PENDING" });
    try {
      if (logoUrl) {
        const storageRef = firebase.storage().refFromURL(logoUrl);
        await storageRef.delete();
      }
      await firebase.firestore().collection("brands").doc(id).delete();
      toast.success("Brand Deleted Successfully");
      dispatch({ type: "DELETE_BRAND_SUCCESS" });
    } catch (err) {
      dispatch({ type: "DELETE_BRAND_REJECTED" });
      toast.error(err.message || "An unknown error occurred");
    }
  };
};
