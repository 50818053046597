import firebase from '../../firebase';
import axios from 'axios';
import { toast } from 'react-toastify';
export const fetchProduct = () => {
    return (dispatch) => {
        try {
            axios.get("https://us-central1-mdp-platform.cloudfunctions.net/fetchProduct")
                .then(res => {
                    console.log('response', res.data.prices.data[0])
                    dispatch({
                        type: "FETCH_PRODUCT",
                        payload: res.data.prices.data[0]
                    })
                }).catch(err => {
                    toast.warning(err.message)
                })
        }
        catch (err) {
            toast.warning(err.message)
        }
    };
};
export const fetchPromos = () => {
    return (dispatch) => {
        try {
            axios.get("https://us-central1-mdp-platform.cloudfunctions.net/getPromoCodes")
                .then(res => {
                    dispatch({
                        type: "FETCH_PROMOS",
                        payload: res.data.data
                    })
                }).catch(err => {
                    toast.warning(err.message)
                })
        }
        catch (err) {
            toast.warning(err.message)
        }
    };
};

export const getAdmin = () => {
    return (dispatch) => {
        firebase.firestore().collection('users')
            .where("type", "==", "superadmin")
            .onSnapshot(query => {
                var temp = [];
                query.forEach((item) => {
                    temp.push({ id: item.id, ...item.data() });

                });
                dispatch({
                    type: 'GET_ADMIN',
                    payload: temp,
                });
            })
    };

}

// export const getCustomerData = (id) => {
//     return async (dispatch) => {
//         try {
//             axios.get("https://us-central1-mdp-platform.cloudfunctions.net/getCustomers")
//                 .then(res => {
//                     console.log('res', res)
//                     dispatch({
//                         type: "FETCH_CUSTOMER_DATA",
//                         payload: res
//                     })
//                 })
//                 .catch(err => {
//                     toast.warning(err.message)
//                 })
//         } catch (err) {
//             toast.warning(err.message)
//         }
//     }
// }
export const getTestCustomers = (obj) => {
    return async (dispatch) => {
        try {
            axios
                .post('https://us-central1-mdp-platform.cloudfunctions.net/getTestCustomer', obj)
                .then(res => {
                    // console.log('response', res)
                    dispatch({
                        type: "FETCH_CUSTOMER_DATA",
                        payload: res.data.customers.Customer
                    })
                })
                .catch(err => {
                    toast.warning(err.message)
                })
        } catch (err) {
            toast.warning(err.message)
        }
    }
}
export const getToken = (param) => {
    return async (dispatch) => {
        try {
            axios
                .post('https://us-central1-mdp-platform.cloudfunctions.net/getToken', param)
                .then(res => {
                    // console.log('res', res.data.token)
                    dispatch({
                        type: "GET_TOKEN",
                        payload: res.data.token
                    })
                })
                .catch(err => {
                    toast.warning(err.message)
                })
        } catch (err) {
            toast.warning(err.message)
        }
    }
}
export const clearGeneral = () => {
    return (dispatch) => {
        dispatch({
            type: "CLEAR_GENERAL"
        })
    }
}