import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Col,
  CardTitle,
} from "reactstrap";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useDispatch, useSelector } from "react-redux";
import WarehouseLocation from "components/WarehouseLocation";
import { fetchBusiness, deleteBusiness } from "store/actions/businessActions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DashboardCard from "components/Cards/DashboardCard";
import Brand from "components/Brand";
import { fetchBrands } from "store/actions/brandActions";
import TickIcon from "assets/img/icons/common/tick.svg";
import CrossIcon from "assets/img/icons/common/cross.svg";
import { deleteBrand } from "store/actions/brandActions";

const Brands = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { isDeleting } = useSelector((state) => state.business);
  const { brands, isFetching, actionLoading } = useSelector(
    (state) => state.brand
  );

  const [brandModal, setBrandModal] = useState(false);
  const [activeBrand, setActiveBrand] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);

  const brandModalToggler = () => {
    if (brandModal) {
      setEditMode(false);
    }
    setBrandModal(!brandModal);
  };

  const deleteModalToggler = () => {
    if (deleteModal) {
      setActiveBrand(null);
    }
    setDeleteModal(!deleteModal);
  };

  const initiateEditBrand = (brand) => {
    setEditMode(true);
    setActiveBrand(brand);
    brandModalToggler();
  };

  const handleDeleteBrand = () => {
    if (activeBrand) {
      dispatch(
        deleteBrand({
          id: activeBrand?.id,
          logoUrl: activeBrand?.logo?.url,
        })
      ).then(() => deleteModalToggler());
    }
  };

  useEffect(() => {
    dispatch(fetchBrands(user?.id));
  }, []);

  return (
    <>
      <DefaultHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="bg-transparent d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Your Brands</h3>
                <Button
                  size="sm"
                  color="primary"
                  className="py-2 px-3"
                  onClick={brandModalToggler}
                >
                  Add Brand
                </Button>
              </CardHeader>
              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Brand Name</th>
                      <th scope="col" className="text-center">
                        Logo
                      </th>
                      <th scope="col" className="text-center">
                        Description
                      </th>
                      <th scope="col" className="text-center">
                        Sales Contact
                      </th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isFetching ? (
                      <tr>
                        <td colSpan="5" className="text-center py-5">
                          <Spinner size="md" color="primary" />
                        </td>
                      </tr>
                    ) : brands?.length > 0 ? (
                      brands.map((brand) => (
                        <tr key={brand.id}>
                          <th scope="row">
                            <span className="mb-0 text-sm">{brand?.name}</span>
                          </th>
                          <td className="text-center">
                            {brand?.logo ? (
                              <img width={32} src={TickIcon} alt="tick" />
                            ) : (
                              'X'
                            )}
                          </td>
                          <td className="text-center">
                            {brand?.description ? (
                              <img width={32} src={TickIcon} alt="tick" />
                            ) : (
                              'X'
                            )}
                          </td>
                          <td className="text-center">
                            {Object.values(brand?.salesContact).every(
                              (value) => value
                            ) ? (
                              <img width={32} src={TickIcon} alt="tick" />
                            ) : (
                              'X'
                            )}
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Button
                                size="sm"
                                color="warning"
                                onClick={() => initiateEditBrand(brand)}
                              >
                                <i className="fas fa-edit" />
                              </Button>
                              <Button
                                size="sm"
                                color="danger"
                                onClick={() => {
                                  setActiveBrand(brand);
                                  deleteModalToggler();
                                }}
                              >
                                <i className="fas fa-trash" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center py-5">
                          <h4 className="font-weight-normal">No Business</h4>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Brand
        modal={brandModal}
        modalToggler={brandModalToggler}
        editMode={editMode}
        activeBrand={activeBrand}
      />
      <Modal isOpen={deleteModal} toggle={deleteModalToggler}>
        <ModalHeader toggle={deleteModalToggler}>Delete Brand</ModalHeader>
        <ModalBody>Are you sure you want to delete this brand?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={deleteModalToggler}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleDeleteBrand}
            disabled={actionLoading}
          >
            Confirm
            {actionLoading && <Spinner size="sm" className="ml-2" />}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Brands;
