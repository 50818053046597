import firebase from "../../firebase";
import { toast } from "react-toastify";

export const login = (creds) => {
  return (dispatch) => {
    dispatch({
      type: "LOGIN_REQUEST",
    });

    firebase
      .auth()
      .signInWithEmailAndPassword(creds.email, creds.password)
      .then((data) => {
        const docRef = firebase
          .firestore()
          .collection("users")
          .doc(data.user.uid);
        docRef.get().then((doc) => {
          if (doc.exists) {
            const userData = doc.data();

            if (userData.type === "admin" || userData.type === "superadmin") {
              toast.warning(
                "The account you are trying to login doesn't have access to this platform"
              );
              firebase.auth().signOut();
              dispatch({
                type: "LOGIN_REQUEST_END",
              });
            } else {
              firebase
                .firestore()
                .collection("users")
                .doc(data.user.uid)
                .onSnapshot((doc) => {
                  dispatch({
                    type: "LOGIN_SUCCESS",
                    user: { id: doc.id, ...doc.data() },
                    error: "",
                  });
                  dispatch({
                    type: "LOGIN_REQUEST_END",
                  });
                });
            }
          } else {
            toast.warning("You do not have access to this panel");
            dispatch({
              type: "LOGIN_REQUEST_END",
            });
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: "LOGIN_FAIL",
          uid: "",
          error: error,
        });
        dispatch({
          type: "LOGIN_REQUEST_END",
        });
        toast.warning(error.message);
      });
  };
};

export const refreshLogin = (uid) => async (dispatch) => {
  firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .onSnapshot((doc) => {
      dispatch({
        type: "LOGIN_SUCCESS",
        user: { id: doc.id, ...doc.data() },
        error: "",
      });
    });
};

export const logout = () => {
  return (dispatch) => {
    firebase
      .auth()
      .signOut()
      .then((data) => {
        localStorage.clear();
        dispatch({
          type: "LOGOUT_SUCCESS",
          uid: "",
          user: "",
          error: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: "LOGOUT_FAIL",
          uid: "",
          error: error,
        });
        toast.warning(error.message);
      });
  };
};
export const forgetpassword = (email) => {
  return (dispatch) => {
    dispatch({
      type: "ACTION_FORGET_REQUEST",
    });
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((res) => {
        dispatch({
          type: "ACTION_FORGET_REQUEST_END",
        });
        toast.success("Recovery Email Sent");
        // alert('Email Sent');
      })
      .catch((err) => {
        dispatch({
          type: "ACTION_FORGET_REQUEST_END",
        });
        toast.success(err.message);
        // alert(err);
      });
  };
};
export const getDistributer = (uid) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then((doc) => {
        dispatch({
          type: "GET_DISTRIBUTER",
          distributer: doc.data(),
        });
      });
  };
};
export const updateProfile = (
  obj,
  uid,
  profileImg = null,
  previousImageUrl = null
) => {
  return async (dispatch) => {
    dispatch({
      type: "PROFILE_UPDATE",
    });
    try {
      const isNewImage = profileImg instanceof File;
      let newImageUrl = "";
      if (isNewImage) {
          if (previousImageUrl) {
            const storageRef = firebase.storage().refFromURL(previousImageUrl);
            await storageRef.delete();
          }
        const newImageRef = firebase
          .storage()
          .ref()
          .child(`profileImages/${uid}/${profileImg?.name}`);
        const snapshot = await newImageRef.put(profileImg);
        newImageUrl = await snapshot.ref.getDownloadURL();
      }
      await firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .update({
          ...obj,
          ...(profileImg &&
            newImageUrl && {
              profileImg: {
                name: profileImg?.name,
                url: isNewImage ? newImageUrl : profileImg?.url,
              },
            }),
        });
      dispatch({
        type: "PROFILE_UPDATE_END",
      });
      dispatch(refreshLogin(uid));
      toast.success("Profile updated successfully");
    } catch (error) {
      dispatch({
        type: "PROFILE_UPDATE_END",
      });
      toast.warning(error.message);
    }
  };
};
export const updateQB = (obj, uid) => {
  return (dispatch) => {
    dispatch({
      type: "PROFILE_UPDATE",
    });
    firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({ client_key: obj.client_key, secret_key: obj.secret_key })
      .then(() => {
        dispatch({
          type: "PROFILE_UPDATE_END",
        });
        toast.success("Integration Added successfully");
      })
      .catch((error) => {
        dispatch({
          type: "PROFILE_UPDATE_END",
        });
        toast.warning(error.message);
      });
  };
};
export const firstPasswordUpdated = (obj) => {
  return (dispatch) => {
    dispatch({
      type: "PASSWORD_REQUEST",
    });
    let user = firebase.auth().currentUser;
    if (user != null) {
      let creds = firebase.auth.EmailAuthProvider.credential(
        user.email,
        obj.userPassword
      );
      user
        .reauthenticateWithCredential(creds)
        .then(() => {
          user.updatePassword(obj.newPassword);
          firebase
            .firestore()
            .collection("users")
            .doc(obj.uid)
            .update({ account: "verified", password: "" });
          dispatch({
            type: "TOGGLE_START",
          });
          dispatch({
            type: "PASSWORD_REQUEST_END",
          });
          dispatch({
            type: "TOGGLE_END",
          });
          toast.success("Password Updated Successfully");
        })
        .catch((error) => {
          toast.warning("Current Password is Invalid");
          dispatch({
            type: "PASSWORD_REQUEST_END",
          });
        });
    } else {
      dispatch({
        type: "PASSWORD_REQUEST",
      });
    }
  };
};
export const clearState = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_AUTH",
    });
  };
};

export const loginAsAdmin =
  ({ uid, onSuccess = () => {}, onError = () => {} }) =>
  async (dispatch) => {
    try {
      logout();
      const docRef = firebase.firestore().collection("users").doc(uid);
      const docSnapshot = await docRef.get();

      if (docSnapshot.exists) {
        const userData = docSnapshot.data();

        dispatch({
          type: "LOGIN_AS_ADMIN_SUCCESS",
          payload: { uid, user: { id: uid, ...userData } },
        });
        onSuccess();
      } else {
        throw new Error("User not found!");
      }
    } catch (error) {
      onError();
    }
  };
