import DefaultHeader from "components/Headers/DefaultHeader";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  Form,
  Spinner,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { updateProfile, getDistributer } from "../../store/actions/authActions";
import DefaultUserIcon from "assets/img/icons/common/user.svg";
import { toast } from "react-toastify";

function Profile() {
  let dispatch = useDispatch();
  let auth = useSelector((state) => state.auth);
  const [phoneNo, setPhoneNo] = useState(auth.user.phoneNo);
  const [firstName, setFirstName] = useState(auth.user.firstName);
  const [lastName, setLastName] = useState(auth.user.lastName);
  const [name, setName] = useState(auth.user.name);
  const [email, setEmail] = useState(auth.user.email);
  const [profileImg, setProfileImg] = useState(auth?.user?.profileImg || null);
  const previousImageUrl = auth?.user?.profileImg?.url || null;
  const handleChange = (value) => {
    const input = value.replace(/\D/g, "").substring(0, 10);
    const first = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      setPhoneNo(`(${first}) ${middle}-${last}`);
    } else if (input.length > 3) {
      setPhoneNo(`(${first}) ${middle}`);
    } else if (input.length >= 0) {
      setPhoneNo(input);
    }
  };
  const getUserType = () => {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (auth != null) {
      return auth.user.type;
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (!file.type.startsWith("image/")) {
      e.target.value = "";
      toast.error("Please select a valid image file.");
      return;
    }
    setProfileImg(file);
  };

  useEffect(() => {
    if (getUserType() != "distributor") {
      dispatch(getDistributer(auth?.user?.distributer || auth?.user?.id));
    }
  }, []);

  return (
    <>
      <DefaultHeader />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent d-flex">
                <h3 className="mb-0">Update Profile</h3>
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    let obj;
                    if (getUserType() == "employee") {
                      obj = {
                        firstName: firstName,
                        lastName: lastName,
                        phoneNo: phoneNo,
                      };
                    } else if (getUserType() == "distributor") {
                      obj = {
                        email,
                        firstName,
                        lastName,
                        name,
                        phoneNo,
                      };
                    } else if (getUserType() == "manufacturer") {
                      obj = {
                        email,
                        firstName,
                        lastName,
                        name,
                        phoneNo,
                      };
                    }
                    dispatch(
                      updateProfile(obj, auth.uid, profileImg, previousImageUrl)
                    );
                  }}
                >
                  <Row>
                    <div className="w-100 d-flex justify-content-center mb-4">
                      <Input
                        id="profileImg"
                        type="file"
                        name="profileImg"
                        onChange={handleImageChange}
                        className="d-none"
                      />
                      <Label
                        type="button"
                        htmlFor="profileImg"
                        className="rounded-circle d-flex align-items-center justify-content-center overflow-hidden"
                        style={{
                          border: "1px solid #CAD1D7",
                          width: "130px",
                          height: "130px",
                        }}
                      >
                        <img
                          type="button"
                          src={
                            profileImg instanceof File
                              ? URL.createObjectURL(profileImg)
                              : profileImg?.url || DefaultUserIcon
                          }
                          alt="user"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Label>
                    </div>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <Label>First Name</Label>
                      <Input
                        required
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Col>
                    <Col sm="6" className="mt-3 mt-sm-0">
                      <Label>Last Name</Label>
                      <Input
                        required
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Col>
                    <Col sm="6" className="mt-3">
                      <Label>
                        {auth?.user?.type === "manufacturer"
                          ? "Manufacturer Name"
                          : "Distribution Name"}
                      </Label>
                      {getUserType() == "employee" ? (
                        <Input
                          required
                          readOnly
                          type="text"
                          value={auth.user.name}
                        />
                      ) : (
                        <Input
                          required
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      )}
                    </Col>
                    <Col sm="6" className="mt-3 ">
                      <Label>Email</Label>
                      <Input required type="email" readOnly value={email} />
                    </Col>
                    <Col sm="6" className="mt-3">
                      <Label>Phone Number</Label>
                      <Input
                        required
                        type="tel"
                        name="phoneNumber"
                        onChange={(e) => handleChange(e.target.value)}
                        placeholder="(XXX)XXX-XXXX"
                        value={phoneNo}
                      />
                    </Col>
                  </Row>
                  <div className="text-right mt-3">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={auth?.profileLoader}
                    >
                      {auth?.profileLoader ? <Spinner size="sm" /> : "Update"}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Profile;
