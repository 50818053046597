import { Card, CardBody, CardTitle, Col, Row, Spinner } from "reactstrap";

const DashboardCard = ({ title, stat, icon, color, isLoading }) => {
  return (
    <Card className="card-stats mb-4 mb-xl-0 mt-xl-4">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
              {title}
            </CardTitle>
            {isLoading ? (
              <div>
                {[...Array(3)].map(() => (
                  <Spinner
                    color="primary"
                    type="grow"
                    size="sm"
                    className="mr-1"
                  />
                ))}
              </div>
            ) : (
              <span className="h2 font-weight-bold mb-0">{stat}</span>
            )}
          </div>
          <Col className="col-auto">
            <div
              className={`icon icon-shape text-white rounded-circle shadow bg-${color}`}
            >
              <i className={icon} />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default DashboardCard;
