/*!
=========================================================
* Argon Dashboard React - v1.2.1
=========================================================
* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from 'react';
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from 'react-copy-to-clipboard';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Spinner,
  ModalFooter,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import { toast } from "react-toastify";
import DefaultHeader from 'components/Headers/DefaultHeader';
import { useSelector } from 'react-redux';
import generator from 'generate-password';

import { connect } from 'react-redux';
import {
  getEmployees,
  addEmployee,
  editEmployee,
  deleteEmployee,
} from '../../store/actions/employees';

const Employees = (props) => {
  let auth = useSelector(state => state.auth)
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [distributer, setDistributer] = useState(auth.user.name);
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [ids, setIds] = useState('');
  const [editObject, setEditObject] = useState({});
  const [modal, setModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [userType, setUserType] = useState('Manager');

  const toggle = () => {
    setModal(!modal);
  };
  const createToggle = () => {
    setCreateModal(!createModal);
  };
  const editToggle = () => {
    setEditModal(!editModal);
  };
  const handleChange = (value) => {
    const input = value.replace(/\D/g, '').substring(0, 10);
    // Divide numbers in 3 parts :"(123) 456-7890"
    const first = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      setPhoneNo(`(${first}) ${middle}-${last}`);
    } else if (input.length > 3) {
      setPhoneNo(`(${first}) ${middle}`);
      console.log(phoneNo);
    } else if (input.length >= 0) {
      setPhoneNo(input);
    }
  };
  useEffect(() => {
    props.getEmployees(auth.uid);

    const user = props.employees;

    console.log(user);
  }, []);
  return (
    <>
      <DefaultHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent d-flex">
                <h3 className="mb-0">Employees</h3>

                <Button
                  onClick={createToggle}
                  color="primary"
                  className="ml-auto"
                >
                  Create Employee
                </Button>
              </CardHeader>

              {/* Create Employee Model */}
              <Modal isOpen={createModal} toggle={createToggle}>
                <ModalHeader toggle={createToggle}>Create Employee</ModalHeader>
                <ModalBody>
                  <div>
                    <Label>First Name</Label>
                    <Input
                      type="text"
                      name="firstname"
                      required
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="Enter First Name"
                      value={firstName}
                    />
                    <Label className="mt-2">Last Name</Label>
                    <Input
                      type="text"
                      name="lastname"
                      required
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Enter Last name"
                      value={lastName}
                    />
                    <Label className="mt-2">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Email"
                      value={email}
                    />
                    <Label className="mt-2">Distributor</Label>
                    <Input
                      type="text"
                      name="distributer"
                      required
                      readOnly
                      value={distributer}
                      onChange={(e) => setDistributer(e.target.value)}
                    />
                    <Label className="mt-2">Phone Number</Label>
                    <Input
                      type="text"
                      name="phoneNo"
                      required
                      onChange={(e) => handleChange(e.target.value)}
                      placeholder="(xxx)xxx-xxxx"
                      value={phoneNo}
                    />

                    <Label className="mt-2" for="User-Type">
                      User-Type
                    </Label>
                    <Input
                      type="select"
                      name="usertype"
                      id="usertype"
                      required
                      onChange={(e) => setUserType(e.target.value)}
                      value={userType}
                    >
                      <option value="default" disabled>Select User Type</option>
                      <option value="Manager">Manager</option>
                      <option value="SalesPerson">Sales Person</option>
                    </Input>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      let obj = {
                        firstName,
                        lastName,
                        email,
                        distributer: auth.uid,
                        account: "not_verified",
                        delete: false,
                        type: "employee",
                        phoneNo,
                        userType,
                        password: generator.generate({
                          length: 8,
                          numbers: true,
                          symbols: true,
                          lowercase: true,
                          uppercase: true,
                          excludeSimilarCharacters: true,
                          strict: true,
                        })
                      };
                      if (firstName == '') {
                        toast.warning('First Name is empty');
                      } else if (lastName == '') {
                        toast.warning('Last Name is empty');
                      } else if (email == '') {
                        toast.warning('Email  is empty');
                      } else if (phoneNo == '') {
                        toast.warning('Phone no is empty');
                      } else if (userType == '') {
                        toast.warning('User type is empty');
                      } else {
                        props.addEmployee(obj);
                        setPhoneNo('');
                        setFirstName("");
                        setLastName("");
                        setEmail('');
                        createToggle();
                      }
                    }}
                  >
                    {props.mdp.employeeLoader ? <Spinner size="sm" /> : "Create Employee"}
                  </Button>
                </ModalFooter>
              </Modal>
              {/* Ended */}
              {/* Edit Employee Model */}
              <Modal isOpen={editModal} toggle={editToggle}>
                <ModalHeader toggle={editToggle}>Edit Employee</ModalHeader>
                <ModalBody>
                  <div>
                    <Label>First Name</Label>
                    <Input
                      type="text"
                      name="firstname"
                      onChange={(e) => setFirstName(e.target.value)}
                      defaultValue={firstName}
                    />
                    <Label className="mt-2">Last Name</Label>
                    <Input
                      type="text"
                      name="lastname"
                      onChange={(e) => setLastName(e.target.value)}
                      defaultValue={lastName}
                    />
                    <Label className="mt-2">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      defaultValue={email}
                    />
                    <Label className="mt-2">Phone Number</Label>
                    <Input
                      type="text"
                      name="phoneNo"
                      onChange={(e) => handleChange(e.target.value)}
                      defaultValue={phoneNo}
                      value={phoneNo}
                    />

                    <Label className="mt-2">User Type</Label>
                    <Input
                      type="select"
                      id="usertype"
                      onChange={(e) => setUserType(e.target.value)}
                      defaultValue={userType}
                      name="days"
                    >
                      <option value="Owner">Owner</option>
                      <option value="Manager">Manager</option>
                      <option value="Sales">Sales</option>
                    </Input>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      let obj = {
                        firstName,
                        lastName,
                        email,
                        phoneNo,
                        userType,
                      };
                      props.editEmployee(ids, obj);
                      setFirstName('');
                      setLastName('');
                      setEmail('');
                      setPhoneNo('');
                      setUserType('');
                      editToggle();
                    }}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </Modal>
              {/* Ended */}
              {/* Delete Modal */}
              <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Delete Employee</ModalHeader>
                <ModalBody>Are you sure you want to delete ? </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      props.deleteEmployee(ids);
                      setIds('');
                      toggle();
                    }}
                  >
                    Confirm
                  </Button>
                  <Button color="danger" onClick={toggle}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
              {/* Ended */}
              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Type</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.employees &&
                      props.employees.map((employ) => (
                        <tr key={employ.id}>
                          <th scope="row">
                            <span className="mb-0 text-sm">
                              {employ.firstName}
                            </span>
                          </th>
                          <td>{employ.lastName}</td>
                          <td>
                            <span className="mb-0 text-sm">{employ.email}</span>
                          </td>
                          <td>
                            <span className="mb-0 text-sm">
                              {employ.phoneNo}
                            </span>
                          </td>
                          <td>
                            <span className="mb-0 text-sm">
                              {employ.userType == "SalesPerson" ? "Sales Person" : employ.userType}
                            </span>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Button
                                onClick={() => {
                                  let obj = employ;
                                  setFirstName(obj.firstName);
                                  setLastName(obj.lastName);
                                  setEmail(obj.email);
                                  setPhoneNo(obj.phoneNo);
                                  setIds(obj.id);
                                  setUserType(obj.userType);
                                  editToggle();
                                }}
                                color="secondary"
                                size="sm"
                              >
                                <i className="fas fa-edit" />
                              </Button>
                              <Button
                                onClick={() => {
                                  let obj = employ.id;
                                  setIds(obj);
                                  console.log('ids', ids);
                                  toggle();
                                }}
                                color="danger"
                                size="sm"
                              >
                                <i className="fas fa-trash" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export const mapStateToProps = (state) => {
  return {
    employees: state.mdp.employees,
    mdp: state.mdp,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    getEmployees: (id) => dispatch(getEmployees(id)),
    addEmployee: (object) => dispatch(addEmployee(object)),
    editEmployee: (id, object) => dispatch(editEmployee(id, object)),
    deleteEmployee: (id) => dispatch(deleteEmployee(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Employees);
