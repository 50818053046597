const initState = {
  businesses: [],
  isAdding: false,
  isFetching: false,
  isUpdating: false,
  isDeleting: false,
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case 'FETCH_BUSINESS_PENDING':
      return {
        ...state,
        isFetching: true,
      };
    case 'FETCH_BUSINESS_SUCCESS':
      return {
        ...state,
        businesses: action.businesses,
        isFetching: false,
      };
    case 'FETCH_BUSINESS_REJECTED':
      return {
        ...state,
        isFetching: false,
      };
    case 'ADD_BUSINESS_PENDING':
      return {
        ...state,
        isAdding: true,
      };
    case 'ADD_BUSINESS_SUCCESS':
      return {
        ...state,
        isAdding: false,
      };
    case 'ADD_BUSINESS_REJECTED':
      return {
        ...state,
        isAdding: false,
      };
    case 'UPDATE_BUSINESS_PENDING':
      return {
        ...state,
        isUpdating: true,
      };
    case 'UPDATE_BUSINESS_SUCCESS':
      return {
        ...state,
        isUpdating: false,
      };
    case 'UPDATE_BUSINESS_REJECTED':
      return {
        ...state,
        isUpdating: false,
      };
    case 'DELETE_BUSINESS_PENDING':
      return {
        ...state,
        isDeleting: true,
      };
    case 'DELETE_BUSINESS_SUCCESS':
      return {
        ...state,
        isDeleting: false,
      };
    case 'DELETE_BUSINESS_REJECTED':
      return {
        ...state,
        isDeleting: false,
      };
    default:
      return state;
  }
}

export default userReducer;