import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "store/actions/authActions";
import DefaultUserIcon from "assets/img/icons/common/user2.svg";

const AdminNavbar = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  return (
    <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
      <Container fluid>
        <div
          className="h4 mb-0 text-white text-uppercase d-none d-md-inline-block"
          to="/"
        >
          {props.brandText}
        </div>
        <Nav className="align-items-center d-none d-md-flex" navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0" nav>
              <Media className="ml-2 d-none d-md-block">
                <span className="mb-0 text-sm font-weight-bold mr-2">
                  {auth.user.firstName} {auth.user.lastName}
                </span>
                <img
                  src={auth?.user?.profileImg?.url || DefaultUserIcon}
                  width={50}
                  height={50}
                  className="rounded-circle"
                  alt="profile"
                />
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>My profile</span>
              </DropdownItem>
              <DropdownItem href="#pablo" onClick={() => dispatch(logout())}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default AdminNavbar;
