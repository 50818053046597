import { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Spinner,
  Col,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux"
import { Redirect } from "react-router";
import { login, clearState } from "../../store/actions/authActions"
import { clearData } from "../../store/actions/businesses"
import { Link } from "react-router-dom";
import { clearGeneral } from "store/actions/generalActions";
import Logo from "assets/img/logo.png";

const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  let dispatch = useDispatch();
  let auth = useSelector(state => state.auth)
  useEffect(() => {
    dispatch(clearState())
    dispatch(clearData())
    dispatch(clearGeneral())
  }, [])
  if (auth.uid) {
    return <Redirect to="/admin/index" />
  }
  return (
    <Col lg="7" md="8" className="my-3">
      <Card className="bg-white shadow-lg border-0 overflow-hidden">
        <div className='text-center px-2'>
          <img width={100} src={Logo} />
          <h1 className="permanent-font">My Distribution Partner</h1>
          <h2 className="cooper-font">Tools for Navigating Your Sales Success & Growth</h2>
        </div>
        <CardBody className="p-4">
          <div className='pb-4 position-relative d-flex align-items-center justify-content-center'>
            <div className='position-absolute bg-dark login-border'></div>
            <h1 className='d-inline-block bg-white px-2 login-font'>Login</h1>
          </div>
          <Form role="form" onSubmit={e => {
            e.preventDefault()
            let creds = {
              email: email,
              password: password
            }
            dispatch(login(creds))
          }}
            className="mx-sm-4"
          >
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative secondary-border">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="secondary-bg">
                    <i className="ni ni-email-83 secondary-color" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email"
                  type="email"
                  autoComplete="new-email"
                  required
                  onChange={e => setEmail(e.target.value)}
                  className="pl-3"
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative secondary-border">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="secondary-bg">
                    <i className="ni ni-lock-circle-open secondary-color" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  type="password"
                  required
                  onChange={e => setPassword(e.target.value)}
                  className="pl-3"
                />
              </InputGroup>
            </FormGroup>
            <div className="text-center">
              <Button
                className="my-3"
                color="primary"
                type="submit"
                disabled={auth.loading}
              >
                {auth.loading ? <Spinner size="sm" /> : "Login"}
              </Button>
            </div>
            <div className="link-primary">
              <Link to="/auth/forget-password">
                <small>Forgot password?</small>
              </Link>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Login;
