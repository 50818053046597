const initState = {
  authError: "",
  uid: "",
  requested: false,
  registered: false,
  user: null,
  loading: false,
  distributer: {},
  passwordLoading: false,
  profileLoader: false,
  asAdmin: false,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_AS_ADMIN_SUCCESS":
      return {
        ...state,
        uid: action.payload.uid,
        user: action.payload.user,
        asAdmin: true,
      }
    case "LOGIN_SUCCESS":
      return {
        ...state,
        uid: action.user.id,
        user: { ...action.user },
        authError: "",
      }
    case "LOGIN_FAIL":
      localStorage.clear()
      return {
        ...state,
        uid: "",
        authError: action.error.message,
      }
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        authError: "",
        uid: "",
        registered: false,
        user: null,
        distributer: {},
      };
    case "ACTION_REQUEST":
      return {
        ...state,
        requested: true,
      }
    case "ACTION_REQUEST_END":
      return {
        ...state,
        requested: false,
      }
    case "ACTION_FORGET_REQUEST":
      return {
        ...state,
        passwordLoading: true,
      }
    case "ACTION_FORGET_REQUEST_END":
      return {
        ...state,
        passwordLoading: false,
      }
    case "PASSWORD_REQUEST":
      return {
        ...state,
        passwordLoading: true,
      }
    case "PASSWORD_REQUEST_END":
      return {
        ...state,
        passwordLoading: false,
      }
    case "PROFILE_UPDATE":
      return {
        ...state,
        profileLoader: true,
      }
    case "PROFILE_UPDATE_END":
      return {
        ...state,
        profileLoader: false,
      }
    case "LOGIN_REQUEST":
      return {
        ...state,
        loading: true,
      }
    case "LOGIN_REQUEST_END":
      return {
        ...state,
        loading: false,
      }
    case "GET_DISTRIBUTER":
      return {
        ...state,
        distributer: action.distributer,
      }
    case "CLEAR_AUTH":
      return {
        authError: "",
        uid: "",
        requested: false,
        registered: false,
        distributer: {},
        user: null,
        loading: false,
        passwordLoading: false,
        profileLoader: false,
      }
    default:
      return {
        ...state
      }
  }
}

export default authReducer